import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label, Spinner } from 'reactstrap';

const ReportUser = ({ isOpen, toggle, onSendMessage,isSending, reportUser }) => {
    
  const [newMessage, setNewMessage] = useState("");


  const handleSendMessage = () => {
    // onSendMessage(newMessage);
      
      if (newMessage.trim() !== "") {
        onSendMessage(newMessage); 
      }

  };

  useEffect(() => {
    if (!isSending) {
      setNewMessage("");
    }
  }, [isSending]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Send Message To Admin</ModalHeader>
      <ModalBody>
        <FormGroup>
        <Label for="message">User Email</Label>
        <Input
            type="text"
            readOnly
            disabled
            name="message"
            id="message"
            value={reportUser?.email}
            onChange={(e) => setNewMessage(e.target.value)}
          />

          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSendMessage}>
          {isSending ? (
            <>
              <Spinner size="sm" />
              <span> Sending... </span>
            </>
          ) : (
            "Send"
          )}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReportUser;
