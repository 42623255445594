import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
  Icon,
} from "../../../components/Component";
import { toast } from "react-toastify";
import { formatDate, getCookie } from "../../../utils/Utils";
import Paypal from "./Paypal";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "./StripePayment";
import { motion } from "framer-motion";
import {
  fetchfreePlan,
  fetchPlanData,
  fetchSubscriptionData,
  getCompanyProfile,
  getPaymentSettings,
} from "../../../http/get/getApi";
import { useQuery, useQueryClient } from "react-query";
import Aos from "aos";
import "aos/dist/aos.css";
import { ShimmerThumbnail } from "react-shimmer-effects";
import paymentSucces from "../../../../src/assets/images/paymentsuccess1.png";
import Swal from "sweetalert2";
import PaymentSuccessAnimation from "../../../PaymentSuccessAnimation.json";
import Lottie from "react-lottie";
import { DataContext } from "../../../context/DataProvider";
import ExceededLimitsModal from "./ExceededLimitsModal ";
import RazorpayPayment from "./RazorpayPayment";
import { getsitesetting } from "../../../http/get/getApi";

// import { sendSMS } from "../../../http/SMS/sms";
// import SMSForm from "../../../http/SMS/sms";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const PlanRegistration = ({ duration_type }) => {
  const [data, setData] = useState(null);
  const [planData, setPlanData] = useState(null);

  const [dataId, setDataId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalTab, setModalTab] = useState(false);
  // const [activeTab, setActiveTab] = useState("1");
  const [planName, setPlanName] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [planEndDate, setPlanEndDate] = useState(null);
  const [freePlan, setFreePlan] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [loadingShimmer, setLoadingShimmer] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const {
    planEnddates,
    setPlanEndDates,
    activeTab,
    setActivetab,
    currency,
    setCurrency,
    transactionid,
    setDurationType,
  } = useContext(DataContext);

  const [photosLimit, setPhotosLimit] = useState(0);
  const [serviceListLimit, setServiceListLimit] = useState(0);
  const [videosLimit, setVideosLimit] = useState(0);
  const [youtubeLinkLimit, setYoutubeLinkLimit] = useState(0);
  const [businessDescriptionLimit, setBusinessDescriptionLimit] = useState(0);
  const [mixersLimit, setMixersLimit] = useState(0);
  const [socialMediaUrlsLimit, setSocialMediaUrlsLimit] = useState(0);

  const [isLimitModalVisible, setIsLimitModalVisible] = useState(false);
  const [exceededLimits, setExceededLimits] = useState([]);

  // get company profile
  const {
    data: companyDetails,
    isLoading: companyprofileLoading,
    isError: companyprofileError,
  } = useQuery({
    queryKey: ["get-company-profile"],
    queryFn: () => getCompanyProfile(),
    staleTime: Infinity,
    // staleTime: 50000,
  });

  const [videoCount, SetvideoCount] = useState();
  const [mixesCount, SetMixesCount] = useState();
  const [descriptioncount, SetDescriPtionCount] = useState();
  const [imagecount, Setimagecount] = useState();
  const [youtubelinkCount, SetYoutubeLinkCount] = useState();
  const [serviceCount, SetServiceCount] = useState(0);
  const [socialMediaCount, setSocialMediaCount] = useState(0);
  const [vendorCount, setVendorCount] = useState();

  useEffect(() => {
    if (companyDetails) {
      Setimagecount(JSON.parse(companyDetails?.data?.profile?.image_count || "[]"));
      SetvideoCount(JSON.parse(companyDetails?.data?.profile?.video_count || "0"));
      SetMixesCount(JSON.parse(companyDetails?.data?.profile?.mixres_count || "0"));
      const descriptionCount = JSON.parse(companyDetails?.data?.profile?.description_count || "0");
      SetDescriPtionCount(descriptionCount);
      setSocialMediaCount(JSON.parse(companyDetails?.data?.profile?.social_media_count || "0"));
      SetYoutubeLinkCount(JSON.parse(companyDetails?.data?.profile?.youtube_link_count || "0"));
      const servicelength = companyDetails?.data?.profile?.services ? companyDetails?.data?.profile?.services : "0";
      SetServiceCount(Object.keys(servicelength).length);
    }
  }, []);

  const {
    data: data1,
    isLoading: isLoading1,
    isError: isError1,
    refetch: refetchSubscriptionPlan,
  } = useQuery({
    queryKey: ["getsubscriptionplans"],
    queryFn: () => fetchSubscriptionData(),
    // staleTime: Infinity,
    enabled: false,
    // staleTime: 5000,

    onSuccess: (responseData) => {
      setData(responseData?.data);
      const ids = responseData?.data.map((plan) => plan.id);
      setPlanData(ids);
      setLoadingShimmer(false);
    },
  });

  const [loadingShimmer, setLoadingShimmer] = useState(isLoading1);

  // const fetchData = async () => {
  //   setLoadingShimmer(true);
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
  //       method: "POST",
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const responseData = await response.json();
  //     // console.log("responseData",responseData.data)
  //     setData(responseData?.data);
  //     const ids = responseData?.data.map((plan) => plan.id);
  //     setPlanData(ids);
  //     setLoadingShimmer(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //   }
  // };

  const [freeplan, setFreePlans] = useState("");

  // const fetchFreePlan = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-free-plan`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     setFreePlans(data.data);
  //     setPlanEndDate(data?.data?.plan_end_date);
  //     setPlanEndDates(data?.data?.plan_end_date);
  //   } catch (error) {
  //     console.error("Error fetching free plan:", error);
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(()=>{
  //     fetchFreePlan();
  //   },6000)
  // }, []);

  const {
    data: freePlanData,
    isLoading: freePlanisLoading1,
    isError: freePlanisError1,
    refetch: refetchFreePlan,
  } = useQuery({
    queryKey: ["getsubscriptionplans"],
    queryFn: () => fetchfreePlan(),
    // staleTime: Infinity,
    enabled: false,
    // staleTime: 5000,

    onSuccess: (data) => {
      setFreePlans(data?.data);
      setPlanEndDate(data?.data?.plan_end_date);
      setPlanEndDates(data?.data?.plan_end_date);
    },
  });

  // const isEmptyObject = (obj) => {
  //   return Object.keys(obj)?.length === 0 && obj?.constructor === Object;
  // };

  const isEmptyObject = (obj) => {
    // Check if obj is not null or undefined, and if it's an object
    if (obj && typeof obj === "object" && obj.constructor === Object) {
      return Object.keys(obj).length === 0;
    }
    // If obj is null or undefined or not an object, consider it as an empty object
    return true;
  };

  const postFreePlanstartdate = async () => {
    try {
      // const formData = new FormData();
      // formData.append("start_date", currentDate);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/free-plan`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        // body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPlanEndDate(data?.data?.end_date);
      setFreePlans(data.data);
    } catch (error) {
      console.error("Error fetching free plan:", error);
    }
  };

  const handleFreePlanClick = () => {
    // const currentDate = new Date().toISOString();
    // const formattedDate = currentDate.split('T')[0];
    Swal.fire({
      title: "Free Plan Activated",
      text: "Your Free Trial plan has Activated for 7 Days",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    postFreePlanstartdate();
    fetchfreePlan();
    // fetchFreePlan();
    // subscribedPlanAPI();
    fetchPlanData();
    // toggleTab(item)
  };

  // const {
  //   data: fetchedPlanData,
  //   isLoading: planDataLoading,
  //   isError: planDataError,
  // } = useQuery({
  //   queryKey: ["get-planData"],
  //   queryFn: () => fetchPlanData(),
  // });

  // console.log(fetchedPlanData?.status === true);
  // useEffect(() => {
  //   if (fetchedPlanData?.status === true) {
  //     // fetchData();
  //     // subscribedPlanAPI();
  //   } else {
  //     // fetchData();
  //   }
  //   Aos.init({ duration: 1000 });
  //   return () => {};
  // }, []);

  const invalidPlanData = () => {
    // queryClient.invalidateQueries("get-planData");
    queryClient.invalidateQueries("subscribedplandata");
  };

  const {
    data: data2,
    isLoading: isLoading2,
    isError: isError2,
    refetch: refetchPlandata,
  } = useQuery({
    queryKey: ["subscribedplandata"],
    queryFn: () => fetchPlanData(),
    // staleTime: Infinity,
    // enabled: false,
    // staleTime: Infinity,
    enabled: false,
    onSuccess: (responseData) => {
      setDataId(responseData?.data?.package?.plan_data?.id);
      // setPlanEndDate(fetchedPlanData?.data?.package?.plan_data?.plan_end_date);
      setPlanEndDate(responseData?.data?.package?.end_date);
      setPlanEndDate(responseData?.data?.package?.end_date);
      setFreePlan(responseData?.data?.package?.plan_data?.id);
      setPlanEndDates(responseData?.data?.package?.end_date);
      setDurationType(responseData?.data?.package?.plan_data?.duration_type);
    },
  });

  useEffect(() => {
    refetchPlandata();
    refetchSubscriptionPlan();
    refetchFreePlan();
  }, [refetchPlandata, refetchSubscriptionPlan, refetchFreePlan]);

  const handleSubmitSubscribePayment = async (status, payment_txn_id, payment_response, amount, mode, newPlanId) => {
    let add_status;
    const plan_id = selectedPlan?.id ? selectedPlan?.id : newPlanId;
    const note = selectedPlan?.name + " plan purchased";
    const payer_id = getCookie("user_id");

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("status", "3");
      formData.append("payment_txn_id", transactionid);
      formData.append("payment_response", "payment_response");
      formData.append("amount", selectedPlan?.discount_price ? selectedPlan?.discount_price : selectedPlan?.price);
      formData.append("payer_id", "1");
      formData.append("payment_mode", "3");
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("mode", "3");
      formData.append("plan_id", plan_id);
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/subscribe-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (planData && planData.status) {
        const submittedData = planData?.data;
        const planData1 = await planData.json();
        setModalData(planData1);
        toast.success("Plan Purchased Successfully");
        invalidPlanData();
        setTimeout(() => {
          setModal(!modal);
          fetchPlanData();
          setModalTab(!modalTab);
        }, 1000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitFreeSubscribePayment = async (planId) => {
    if (planId === undefined) {
      toast.error("Something went to wrong! Contact to adminstrator");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("plan_id", planId);
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-free-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      if (planData && planData.status) {
        const submittedData = planData?.data;
        invalidPlanData();
        toast.success("Free-Plan Purchased Successfully");

        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleTab = (plan) => {
    setLoading(true);
    setModalTab(!modalTab);
    setSelectedPlan(plan);
    setLoading(false);
    setInputValues({
      cardNumber: "",
      expiryDate: "",
      cardHolderName: "",
      cvv: "",
    });
    setPAyButton(false);
  };

  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const BtnHover = {
    hover: {
      y: 1,
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [payButton, setPAyButton] = useState(false);

  const dummyCardDetails = {
    cardNumber: "1234 5678 9012 3456",
    expiryDate: "12/24",
    cardHolderName: "John Doe",
    cvv: "123",
  };

  const [inputValues, setInputValues] = useState({
    cardNumber: "",
    expiryDate: "",
    cardHolderName: "",
    cvv: "",
  });

  // Function to format card number
  const formatCardNumber = (value) => {
    // Remove all non-digit characters
    const onlyNums = value.replace(/\D/g, "");

    // Format with spaces every 4 digits
    const formattedValue = onlyNums.replace(/(.{4})/g, "$1 ").trim();

    return formattedValue;
  };

  // Function to format expiry date
  const formatExpiryDate = (value) => {
    const onlyNums = value.replace(/\D/g, "");
    const formattedValue = onlyNums.replace(/^(\d\d)(\d)$/g, "$1/$2").replace(/^(\d\d)\/(\d\d).*$/g, "$1/$2");
    return formattedValue;
  };

  const areAllFieldsFilled = () => {
    return inputValues.cardNumber && inputValues.expiryDate && inputValues.cardHolderName && inputValues.cvv;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [id]: id === "cardNumber" ? formatCardNumber(value) : id === "expiryDate" ? formatExpiryDate(value) : value,
      };

      // Check if all fields are filled
      if (areAllFieldsFilled()) {
        setPAyButton(true);
      } else {
        setPAyButton(false);
      }

      return updatedValues;
    });
  };

  const handleUseCardDetails = () => {
    setPAyButton(true);
    setInputValues({
      cardNumber: dummyCardDetails.cardNumber,
      expiryDate: dummyCardDetails.expiryDate,
      cardHolderName: dummyCardDetails.cardHolderName,
      cvv: dummyCardDetails.cvv,
    });
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: PaymentSuccessAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const checkLimitsExceeded = (item) => {
    // Parse limits
    const photosLimit = JSON.parse(item?.photos || "0");
    const serviceListLimit = JSON.parse(item?.service_list || "0");
    const videosLimit = JSON.parse(item?.videos || "0");
    const youtubeLinkLimit = item?.youtube_link === "Unlimited" ? Infinity : JSON.parse(item?.youtube_link || "0");
    const businessDescriptionLimit = JSON.parse(item?.business_description || "0");
    const mixersLimit = JSON.parse(item?.mixes || "0");
    const socialMediaUrlsLimit =
      item?.social_media_urls === "Unlimited" ? Infinity : JSON.parse(item?.social_media_urls || "0");

    // Set limits in state
    setPhotosLimit(photosLimit);
    setServiceListLimit(serviceListLimit);
    setVideosLimit(videosLimit);
    setYoutubeLinkLimit(youtubeLinkLimit);
    setBusinessDescriptionLimit(businessDescriptionLimit);
    setMixersLimit(mixersLimit);
    setSocialMediaUrlsLimit(socialMediaUrlsLimit);

    // Return whether limits are exceeded
    return {
      imagesExceeded: imagecount > photosLimit,
      videosExceeded: videoCount > videosLimit,
      mixesExceeded: mixesCount > mixersLimit,
      socialMediaExceeded: socialMediaCount > socialMediaUrlsLimit,
      youtubeLinksExceeded: youtubelinkCount > youtubeLinkLimit,
      servicesExceeded: serviceCount > serviceListLimit,
      descriptionExceeded: descriptioncount > businessDescriptionLimit,
    };
  };

  const {
    data: data3,
    isLoading: isLoading3,
    isError: isError3,
    refetch: refetchPaymentSttings,
  } = useQuery({
    queryKey: ["getpaymentSettings"],
    queryFn: () => getPaymentSettings(),
    staleTime: Infinity,
  });

  const [peice, setPrice] = useState();
  const handlePlanClick = (item) => {
    // setActiveTab(`${data3?.data?.PaymentSetting[0]?.id}`)
    refetchPaymentSttings();
    if (data3?.data?.PaymentSetting?.length > 0) {
      setActiveTab(`${data3?.data?.PaymentSetting[0].id}`);
    } else {
      console.warn("PaymentSetting is undefined or empty");
      setActiveTab(null);
    }
    setPrice(item);
    const {
      imagesExceeded,
      videosExceeded,
      mixesExceeded,
      socialMediaExceeded,
      youtubeLinksExceeded,
      servicesExceeded,
      descriptionExceeded,
    } = checkLimitsExceeded(item);
    const exceeded = [];
    if (imagesExceeded) exceeded.push("Images");
    if (videosExceeded) exceeded.push("Videos");
    if (mixesExceeded) exceeded.push("Mixes");
    if (socialMediaExceeded) exceeded.push("Social Media");
    if (youtubeLinksExceeded) exceeded.push("YouTube Links");
    if (servicesExceeded) exceeded.push("Services");
    if (descriptionExceeded) exceeded.push("Business Description");

    if (exceeded.length > 0) {
      setExceededLimits(exceeded);
      setIsLimitModalVisible(true);
      return;
    } else {
      // if (isEmptyObject(freeplan) || !freeplan.plan_end_date) {
      //   if (item.name === "SILVER" && item.duration_type === "m") {
      //     // If the user chooses the Silver monthly plan, apply free trial
      //     handleFreePlanClick();
      //     return;
      //   }
      // }

      // Directly handle purchase of paid plans (Gold, Platinum, and Yearly plans)
      toggleTab(item);
    }
  };

  const [activeTab1, setActiveTab] = useState(""); // Default to Stripe (tab 1)
  const togglePaymentTab = (tab) => {
    if (activeTab1 !== tab) {
      setActiveTab(tab);
    }
  };

  // useEffect(()=>{
  //   getPaymentSettings()
  //   setActiveTab(`${data3?.data?.PaymentSetting[0]?.id}`)
  // },[])

  return (
    <React.Fragment>
      {loadingShimmer ? (
        <div>
          <Row className="w-100">
            <Col md={12} className="border p-3 justify-center">
              <Row>
                <Col md={4} className=" p-3 bg-white">
                  <ShimmerThumbnail height={500} width={400} rounded />
                </Col>
                <Col md={4} className=" p-3 bg-white">
                  <ShimmerThumbnail height={500} width={400} rounded />
                </Col>
                <Col md={4} className=" p-3 bg-white">
                  <ShimmerThumbnail height={500} width={400} rounded />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Head title="Pricing Table"></Head>
          <Content>
            <BlockHead size="sm" className="d-flex justify-between">
              {freePlan === "0" ? (
                <>
                  <Card
                    className={
                      new Date(planEndDate) > new Date()
                        ? "border-3 border-primary round-xl"
                        : new Date(planEndDate) < new Date()
                        ? "border-3 border-danger round-xl"
                        : ""
                    }
                  >
                    <BlockBetween className="g-3 mx-1 my-1">
                      <BlockContent>
                        <BlockTitle className="fs-4 text-center">
                          Free Plan{" "}
                          {new Date(planEndDate) > new Date() ? (
                            <span className="fs-3 text-primary" data-aos="fade-up">
                              Activated
                            </span>
                          ) : new Date(planEndDate) < new Date() ? (
                            <span className="fs-3 text-danger" data-aos="fade-up">
                              Subscription Ended
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                        </BlockTitle>
                        <BlockDes className="text-soft">
                          <p>This plan is Activated until the {planEndDate}.</p>
                        </BlockDes>
                      </BlockContent>
                    </BlockBetween>
                  </Card>
                </>
              ) : (
                <></>
              )}
            </BlockHead>

            <Block>
              <Row className="g-gs justify-center">
                {data &&
                  data.map((item) => {
                    if (
                      (item.duration_type === "m" && duration_type === "m") ||
                      (item.duration_type === "y" && duration_type === "y")
                    ) {
                      if (dataId && item.id === "0") {
                        return null;
                      }
                      return (
                        <Col md={6} lg={3} key={item.id}>
                          <Card
                            className={
                              dataId === item.id && new Date(planEndDate) > new Date()
                                ? "border-3 border-primary round-xl"
                                : dataId === item.id && new Date(planEndDate) < new Date()
                                ? "border-3 border-danger round-xl"
                                : ""
                            }
                          >
                            {dataId === item.id ? (
                              <>
                                {new Date(planEndDate) > new Date() ? (
                                  <>
                                    <div className="fw-semibold text-white fs-5 bg-primary text-center py-2">
                                      <span data-aos="fade-down">
                                        <Icon name="star-round" /> Active Plan
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="fw-semibold text-white fs-5 bg-danger text-center py-2">
                                      <span data-aos="fade-down">
                                        <Icon name="cross-circle" /> Current Plan Expired
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <div className={dataId === item.id ? "pricing-head pt-3 p-0" : "pricing-head"}>
                              <div className="pricing-title p-1">
                                <h4 className="card-title title">{item.name}</h4>
                              </div>
                              <div className="card-text">
                                <Row>
                                  <Col size={12}>
                                    {item?.id === "0" ? (
                                      <span className="h5 fw-400">Free</span>
                                    ) : (
                                      <div style={{ display: "block" }}>
                                        {/* <span className="h5 fw-400" style={{ marginRight: '10px', display:'block' }}>$ {item?.price}/month</span> */}
                                        {/* <span className="h5 fw-400" style={{ marginRight: "10px", display: "block" }}>
                                          $ {item.discount ? item.discount_price : item?.price}/{item?.duration_type === "m" ? "month" : "year"}
                                        </span> */}
                                        <span className="h5 fw-400" style={{ marginRight: "10px", display: "block" }}>
                                          {item.discount ? (
                                            <>
                                              <span style={{ textDecoration: "line-through", marginRight: "10px" }}>
                                                {currency} {parseFloat(item.price)?.toFixed(2)}
                                              </span>
                                              <span>
                                                {currency} {parseFloat(item?.discount_price)?.toFixed(2)}/
                                                {item?.duration_type === "m" ? "month" : "year"}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              $ {parseFloat(item?.price)?.toFixed(2)}/
                                              {item?.duration_type === "m" ? "month" : "year"}
                                            </>
                                          )}
                                        </span>

                                        {item?.id == dataId ? (
                                          <span className="font" style={{ fontSize: "13px", color: "#FF0000" }}>
                                            Expires On : {formatDate(planEndDate)}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="pricing-body">
                              <ul className="pricing-features text-body">
                                <li>
                                  <span className="w-50 ">CRM</span> - <span className="ms-auto">Platform</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Employee</span> -{" "} */}
                                  <span className="w-50 ">Business Description</span> -{" "}
                                  <span className="ms-auto">{item?.business_description} words</span>
                                  {/* <span className="ms-auto">{item?.business_description ? JSON.parse(item.business_description).text : ""}</span> */}
                                </li>
                                <li>
                                  {/* <span className="w-50">Client</span> -{" "} */}
                                  <span className="w-50">Photos</span> - <span className="ms-auto">{item?.photos}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Event Limit</span> -{" "} */}
                                  <span className="w-50">Video (Uploaded)</span> -{" "}
                                  <span className="ms-auto">{item?.videos}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Management</span> -{" "} */}
                                  <span className="w-50">Video (YouTube)</span> -{" "}
                                  <span className="ms-auto">{item?.youtube_link}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Technical Support</span> -{" "} */}
                                  <span className="w-50">Mixes</span> - <span className="ms-auto">{item?.mixes}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Website Tool</span> -{" "} */}
                                  <span className="w-50">Social Media URLs</span> -{" "}
                                  <span className="ms-auto">{item?.social_media_urls}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Enhanced Support</span> -{" "} */}
                                  <span className="w-50">Services Listings</span> -{" "}
                                  <span className="ms-auto">Up to {item?.service_list}</span>
                                </li>
                                <li className="d-none">
                                  {/* <span className="w-50">Advanced Features</span> -{" "} */}
                                  <span className="w-50">Customized Contact Form</span> -{" "}
                                  <span className="ms-auto">{item?.customize_contact_form}</span>
                                </li>
                                <li>
                                  {/* <span className="w-50">Advanced Features</span> -{" "} */}
                                  <span className="w-50">Go-Live streaming</span> -{" "}
                                  <span className="ms-auto">{item?.go_live_streaming}</span>
                                </li>
                              </ul>
                              <div className={dataId === item.id ? "pricing-action mt-3 m-0" : "pricing-action"}>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    handlePlanClick(item);
                                    checkLimitsExceeded(item);
                                  }}
                                >
                                  {/* {(isEmptyObject(freeplan) || !freeplan.plan_end_date) &&
                                  item.name === "SILVER" &&
                                  item.duration_type === "m" ? (
                                    <motion.span variants={BtnHover} whileHover={"hover"}>
                                      <span className="sub-text text-white">Free Trial</span>
                                    </motion.span>
                                  ) : ( */}
                                  <motion.span variants={BtnHover} whileHover={"hover"}>
                                    <span className="sub-text text-white">
                                      {!dataId ? "Buy Now" : item?.id === dataId ? "Renew Now" : "Buy Now"}
                                    </span>
                                  </motion.span>
                                  {/* )} */}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    }
                  })}
              </Row>
            </Block>

            <Modal size="lg" isOpen={modalTab} toggle={toggleTab}>
              <ModalHeader toggle={toggleTab}>Select Payment Method</ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Nav tabs>
                    {isLoading3 ? (
                      <div>Loading...</div> // Show a loading state while fetching data
                    ) : isError3 ? (
                      <div>Error loading payment settings</div> // Show an error message if there's an issue
                    ) : (
                      data3?.data?.PaymentSetting?.map((paymentMethod, index) => (
                        <NavItem key={paymentMethod.id}>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeTab1 === `${paymentMethod?.id}` })} // Set active class based on index
                            onClick={(ev) => {
                              ev.preventDefault();
                              togglePaymentTab(`${paymentMethod?.id}`); // Toggle tab based on index
                            }}
                          >
                            <Icon name={paymentMethod?.icon} /> <span>{paymentMethod?.payment_type}</span>{" "}
                            {/* Use icon and name from data */}
                          </NavLink>
                        </NavItem>
                      ))
                    )}
                  </Nav>
                  <div className="plan-price-container text-end mt-2">
                    <span className="plan-name d-block">Plan: {peice?.name}</span>
                    <span className="plan-price d-block">
                      Price: {currency}{" "}
                      {parseFloat(peice?.discount_price ? peice?.discount_price : peice?.price)?.toFixed(2)}
                    </span>
                  </div>
                </div>

                <TabContent activeTab={activeTab1}>
                  {data3?.data?.PaymentSetting?.map((paymentMethod) => (
                    <TabPane tabId={`${paymentMethod?.id}`} key={paymentMethod?.id}>
                      {paymentMethod?.payment_type === "Pay with Card" && (
                        // {paymentMethod?.payment_type === "Stripe" && (
                        <StripePayment
                          price={peice?.discount_price ? peice?.discount_price : peice?.price}
                          data={peice}
                          toggleTab={toggleTab}
                          loading={loading}
                          selectedPlan={selectedPlan}
                          setModalData={setModalData}
                          invalidPlanData={invalidPlanData}
                          setModal={setModal}
                          modal={modal}
                          refetchPlandata={refetchPlandata}
                          setModalTab={setModalTab}
                          modalTab={modalTab}
                          client_key={paymentMethod.client_key}
                        />
                      )}
                      {paymentMethod?.payment_type === "Paypal" && (
                        <>
                          <div className="d-flex w-100">
                            <span className="me-2 fs-5 lead-text">Pay with</span>
                            <span className="w-30">
                              <Paypal
                                data={peice}
                                price={peice?.discount_price ? peice.discount_price : peice?.price}
                                setModalData={setModalData}
                                modalTab={modalTab}
                                setModalTab={setModalTab}
                                modal={modal}
                                setModal={setModal}
                                invalidPlanData={invalidPlanData}
                                refetchPlandata={refetchPlandata}
                                publickey={data3?.data?.PaymentSetting?.[1]?.client_key}
                              />
                            </span>
                          </div>
                        </>
                      )}
                      {paymentMethod?.payment_type === "Razor Pay" && (
                        <RazorpayPayment
                          price={peice?.discount_price ? peice?.discount_price : peice?.price}
                          payid={peice?.id}
                          setModalTab={setModalTab}
                          modalTab={modalTab}
                          handleSubmitSubscribePayment={handleSubmitSubscribePayment}
                        />
                      )}
                    </TabPane>
                  ))}
                </TabContent>
              </ModalBody>
            </Modal>

            <Modal isOpen={modal}>
              <ModalHeader style={{ textAlign: "center", width: "100%" }} toggle={() => setModal(!modal)}>
                Payment Details
              </ModalHeader>
              <ModalBody>
                {modalData ? (
                  <div style={{ position: "relative" }}>
                    <p>
                      <span style={{ marginRight: "10px" }}> Plan :</span> {modalData?.data?.plan_data?.name}
                    </p>
                    <p>
                      <span style={{ marginRight: "10px" }}> Amount :</span>
                      {`${currency || ""}` + `${modalData?.data?.price}`}
                    </p>
                    <p>
                      <span style={{ marginRight: "10px" }}> Transaction ID: </span> {modalData?.data?.txn_id}
                    </p>
                    <p>
                      <span style={{ marginRight: "10px" }}> Start Date: </span> {modalData?.data?.start_date}
                    </p>
                    <p>
                      <span style={{ marginRight: "10px" }}> End Date: </span> {modalData?.data?.end_date}
                    </p>

                    <Lottie
                      options={defaultOptions}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "80%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        zIndex: 5,
                      }}
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <p>Loading...</p>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => navigate("/Company-profile/edit-profile")}>
                  Go To Edit Profile
                </Button>
              </ModalFooter>
            </Modal>
            <ExceededLimitsModal
              isVisible={isLimitModalVisible}
              onClose={() => setIsLimitModalVisible(false)}
              exceededLimits={exceededLimits}
            />
          </Content>
        </>
      )}
    </React.Fragment>
  );
};

export default PlanRegistration;
