import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, orderBy, limit, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";
import { ChatContext } from "../Chat Files/ChatContext";

function ChatLastMessage({ itemId, currentUserId, selectedId }) {
  const [lastMessage, setLastMessage] = useState("Loading last message...");
  const [lastMessageTime, setLastMessageTime] = useState("");
  const [last, setLast] = useState("");
  const [chatCount, setChatCount] = useState(0);

  useEffect(() => {
    // Set up a listener to get the last message in real-time
    async function setupLastMessageListener() {
      try {
        // Query to find the chatroom between the current user and the specified item ID
        const chatRoomsQuery = query(collection(db, "Chats"), where("participants", "array-contains", currentUserId));

        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        let chatRoomId = null;

        chatRoomsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.participants.includes(itemId)) {
            chatRoomId = doc.id;
          }
        });

        if (!chatRoomId) {
          setLastMessage("No messages yet");
          return;
        }

        // Real-time listener for the latest message in the chatroom's messages subcollection
        const messagesQuery = query(
          collection(db, "Chats", chatRoomId, "messages"),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        // Unread message query && set the Unread message chatting count using "chatRoomId"
        const unreadMessagesQuery = query(collection(db, "Chats", chatRoomId, "messages"), where("read", "==", true));
        const unsubscribeUnreadMessages = onSnapshot(unreadMessagesQuery, (snapshot) => {
          if (!snapshot.empty) {
            const messages = snapshot.docs.map((doc) => doc.data());
            const messagesCount = messages.filter((data) => data.senderId !== currentUserId);
            // console.log(messagesCount);
            setChatCount(messagesCount.length);
          } else setChatCount(0);
        });

        const unsubscribeLastMessage = onSnapshot(messagesQuery, (snapshot) => {
          if (!snapshot.empty) {
            const latestMessage = snapshot.docs[0].data();

            setLast(latestMessage);
            setLastMessage(
              latestMessage?.contactShare
                ? "🪪ContactCard"
                : latestMessage?.deleted
                ? "Message has been deleted"
                : latestMessage?.fileURLs?.length > 0
                ? "🖇️Attachments"
                : latestMessage?.text || "No messages yet"
            );
            setLastMessageTime(
              new Date(latestMessage?.timestamp?.seconds * 1000).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            );
          } else {
            setLastMessage("No messages yet");
          }
        });

        // Clean up listeners on unmount
        return () => {
          unsubscribeLastMessage();
          unsubscribeUnreadMessages();
        };
      } catch (error) {
        console.error("Error setting up listener for last message:", error);
      }
    }

    setupLastMessageListener();
  }, [itemId, currentUserId]);

  return (
    <>
      <div className="d-flex justify-between">
        {itemId === selectedId ? (
          <p className="m-0">{lastMessage} </p>
        ) : (
          <>
            <p className={chatCount === 0 ? "" : "m-0 lead-text"}>{lastMessage} </p>
            {chatCount === 0 ? "" : <p className="m-0 badge bg-primary align-center rounded-circle">{chatCount} </p>}
          </>
        )}
      </div>
      <p className="m-0">{lastMessageTime}</p>
    </>
  );
}

export default ChatLastMessage;
