import Swal from "sweetalert2";
import { dataURLtoFile, getCookie, parseDateString } from "../../utils/Utils";
import { Navigate, useNavigate } from "react-router";
import RedirectToPath, { redirectToPath } from "../../utils/helper";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
// import _ from 'lodash';

const baseUrl = "https://api.ipartydjcrm.com/api/v1";
// let token = process.env.REACT_APP_AUTH_TOKEN;

// Post API to add event  details =================================
export const addEventDetails = async (values) => {
  const formData = new FormData();

  formData.append("client_id", values.client_id);
  formData.append("event_name", values.event_name);
  formData.append("event_type", values.event_type);
  formData.append("start_date_time", parseDateString(values.startTime));
  formData.append("end_date_time", parseDateString(values.end_date_time));

  if (values.venue_id) {
    formData.append("venue_id", values.venue_id);
  }

  if (values.addons) {
    formData.append("addons", JSON.stringify(values.addons));
  } else {
  }
  if (values.attire) {
    formData.append("attire", values.attire);
  }
  if (values.setup_time) {
    formData.append("setup_time", parseDateString(values.setup_time));
  }
  if (values.booked_date) {
    formData.append("booked_date", parseDateString(values.booked_date));
  }
  if (values.booking_status) {
    formData.append("booking_status", values.booking_status);
  }

  if (values.contract_due_date) {
    formData.append("contract_due_date", parseDateString(values.contract_due_date));
  }

  if (values.contract_sent_date) {
    formData.append("contract_sent_date", parseDateString(values.contract_sent_date));
  }
  if (values.intial_contract_date) {
    formData.append("intial_contract_date", parseDateString(values.intial_contract_date));
  }

  if (values.guest_count) {
    formData.append("guest_count", values.guest_count);
  }
  if (values.inquiry_source) {
    formData.append("inquiry_source", values.inquiry_source);
  }
  if (values.instant_quote_id) {
    formData.append("instant_quote_id", values.instant_quote_id);
  }
  if (values.internal_notes_booking) {
    formData.append("internal_notes", values.internal_notes_booking);
  }
  if (values.internal_notes_resources) {
    formData.append("internal_notes", values.internal_notes_resources);
  }
  if (values.next_action) {
    formData.append("next_action", values.next_action);
  }
  if (values.next_action_date) {
    formData.append("next_action_date", parseDateString(values.next_action_date));
  }
  if (values.intial_contract_date) {
    formData.append("intial_contract_date", parseDateString(values.intial_contract_date));
  }
  if (values.package_id) {
    formData.append("package_id", values.package_id);
  }
  if (values.retainer_value) {
    formData.append("retainer_value", values.retainer_value);
  }
  if (values.rfi_form_id) {
    formData.append("rfi_form_id", values.rfi_form_id);
  }
  if (values.sales_person_id) {
    formData.append("sales_person_id", values.sales_person_id);
  }
  if (values.secondary_venue_id) {
    formData.append("secondary_venue_id", values.secondary_venue_id);
  }

  if (values.employees) {
    formData.append("employees", JSON.stringify(values.employees));
  }
  if (values.scheduled_payment_2) {
    formData.append("scheduled_payment_2", parseDateString(values.scheduled_payment_2));
  }
  if (values.scheduled_payment_2_value && values.scheduled_payment_2_value !== "Done") {
    formData.append("scheduled_payment_2_value", values.scheduled_payment_2_value);
  }
  if (values.scheduled_payment_3) {
    formData.append("scheduled_payment_3", parseDateString(values.scheduled_payment_3));
  }

  if (values.scheduled_payment_3_value !== "Done" && values.scheduled_payment_3_value) {
    formData.append("scheduled_payment_3_value", values.scheduled_payment_3_value);
  }
  if (values.package_overtime) {
    formData.append("overtime_rate_hourly", values.package_overtime);
  }
  if (values.overtimeRateHalfHourly) {
    formData.append("overtime_rate_half_hourly", values.overtimeRateHalfHourly);
  }
  if (values.travel_cost) {
    formData.append("travel_fee", values.travel_cost);
  }
  if (values.discount1) {
    formData.append("discount_1", values.discount1);
  }
  if (values.discount2) {
    formData.append("discount_2", values.discount2);
  }
  if (values.add_on_total) {
    formData.append("addon_total", values.add_on_total);
  }
  if (values.balanceDue) {
    formData.append("balance_due_after_retainer", values.balanceDue);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/events/add-event`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/add-event`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding event ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();
  return res;
};

// Post API to add package  details =================================
export const addPackage = async (values) => {
  function generateUniqueFileName(originalFileName) {
    const fileExtension = originalFileName?.split(".").pop();
    const uniqueName = `${uuidv4()}.${fileExtension}`;

    return uniqueName;
  }

  const formData = new FormData();

  formData.append("package_name", values?.package_name);
  formData.append("package_price", values?.package_price);

  // if (values.package_file) {
  //   const userImage = dataURLtoFile(values.package_file, "packageImage");
  //   formData.append("package_file", userImage);
  // }
  // const base64 = values.package_file?.split(",");
  if (values.package_file) {
    // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    // if (base64regex.test(base64[1])) {
    // const userImage = dataURLtoFile(values.package_file, "packageImage");
    const userImage = dataURLtoFile1(values?.package_file, generateUniqueFileName("packageImage.png"));
    formData.append("package_file", userImage);
    // }
  } else if (!values.package_file && values.imagePresent == 0) {
    formData.append("package_file", "");
  }

  if (values.package_deposite_price) {
    formData.append("package_deposite_price", values.package_deposite_price);
  }

  if (values.package_overtime) {
    formData.append("package_overtime", values.package_overtime);
  }

  if (values.package_duration) {
    formData.append("package_duration", values.package_duration);
  }
  if (values.description) {
    formData.append("description", values.description);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/package/add-package`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/package/add-package`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding package ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post API to add venue  details =================================
export const addVenue = async (values) => {
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page");

  const formData = new FormData();

  formData.append("venue_name", values.venue_name);
  formData.append("address", values.address);
  formData.append("city", values.city);
  formData.append("country", values.country);
  formData.append("state", values.state);
  formData.append("zip_code", values.zip_code);
  formData.append("email_address", values.email_address);
  formData.append("phone_number", values.phone_number);
  if (values.office_telephone) {
    formData.append("office_telephone", values.office_telephone);
  }
  if (values.distance) {
    formData.append("distance", values.distance);
  }
  if (values.travel_cost) {
    formData.append("travel_cost", values.travel_cost);
  }

  if (values.notes) {
    formData.append("notes", values.notes);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/venue/add-venue`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/venue/add-venue`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding venue ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post API to add vender  details =================================
export const addVender = async (values) => {
  const formData = new FormData();

  formData.append("first_name", values.first_name);
  formData.append("last_name", values.last_name);
  formData.append("email", values.email);
  formData.append("business_phone", values.business_phone);
  
  if(values.username){
    formData.append("username", values.username);
  }
  
  if(values.password){
    formData.append("password", values.password);
  }

  if (values.files) {
    const userImage = dataURLtoFile(values.files, "userImage");
    formData.append("files", userImage);
  }
  if (values.company_name) {
    formData.append("company_name", values.company_name);
  }
  if (values.website) {
    formData.append("website", values.website);
  }
  if (values.business_fax) {
    formData.append("business_fax", values.business_fax);
  }
  if (values.business_address) {
    formData.append("business_address", values.business_address);
  }
  if (values.business_city) {
    formData.append("business_city", values.business_city);
  }
  if (values.business_zip_code) {
    formData.append("business_zip_code", values.business_zip_code);
  }
  if (values.business_country) {
    formData.append("business_country", values.business_country);
  }
  if (values.business_state) {
    formData.append("business_state", values.business_state);
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }
  if (values.fax_phone) {
    formData.append("fax_phone", values.fax_phone);
  }
  if (values.cell_phone) {
    formData.append("cell_phone", values.cell_phone);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }
  if (values.city) {
    formData.append("city", values.city);
  }
  if (values.country) {
    formData.append("country", values.country);
  }
  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }
  if (values.spouse_name) {
    formData.append("spouse_name", values.spouse_name);
  }
  if (values.birthday) {
    formData.append("dob", parseDateString(values.birthday));
  }
  if (values.anniversary) {
    formData.append("anniversary", parseDateString(values.anniversary));
  }
  if (values.notes) {
    formData.append("notes", values.notes);
  }
  if (values.date_added_to_system) {
    formData.append("date_added_to_system", parseDateString(values.date_added_to_system));
  }
  if (values.is_this_vendor) {
    formData.append("is_this_vendor", values.is_this_vendor);
  }
  if (values.prefer_vendor) {
    formData.append("prefer_vendor", values.prefer_vendor);
  }
  if (values.is_owner == 0 || values.is_owner == 1) {
    formData.append("is_owner", values.is_owner);
  }
  if (values.representative_name) {
    formData.append("representative_name", values.representative_name);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/vender/add-vender`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/vender/add-vender`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding vender ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post API to add client  details =================================
export const addClient = async (values) => {
  const formData = new FormData();

  formData.append("username", values.username);
  // if (values.username) {
  //   formData.append("username", values.username);
  // }
  formData.append("password", values.password);

  // formData.append("first_name", values.first_name);
  if (values.first_name) {
    formData.append("first_name", values.first_name);
  }

  // formData.append("last_name", values.last_name);
  if (values.last_name) {
    formData.append("last_name", values.last_name);
  }

  // formData.append("email", values.email);
  if (values.email) {
    formData.append("email", values.email);
  }

  // formData.append("work_phone_number", values.work_phone_number);
  if (values.work_phone_number) {
    formData.append("work_phone_number", values.work_phone_number);
  }

  if (values.files) {
    const userImage = dataURLtoFile(values.files, "userImage");
    formData.append("files", userImage);
  }

  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }
  if (values.address_2) {
    formData.append("address_2", values.address_2);
  }
  if (values.country) {
    formData.append("country", values.country);
  }
  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.dob) {
    formData.append("dob", parseDateString(values.dob));
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }
  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }
  if (values.city) {
    formData.append("city", values.city);
  }
  if (values.anniversary_date) {
    formData.append("anniversary_date", parseDateString(values.anniversary_date));
  }

  if (values.notes) {
    formData.append("notes", values.notes);
  }
  if (values.client_type) {
    formData.append("client_type", values.client_type);
  }
  if (values.client_number) {
    formData.append("client_number", values.client_number);
  }
  if (values.planning_lockout) {
    formData.append("planning_lockout", values.planning_lockout);
  }
  if (values.organization_name) {
    formData.append("organization_name", values.organization_name);
  }
  if (values.password_change === 1) {
    formData.append("password_change", values.password_change);
  }
  if (values.all_portal_change === 2) {
    formData.append("all_portal_change", values.all_portal_change);
  }

  if (values.company_detail) {
    formData.append("company_detail", values.company_detail);
  }

  if (values.organization_detail) {
    formData.append("organization_detail", values.organization_detail);
  }

  if (values.school_detail) {
    formData.append("school_detail", values.school_detail);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/client/add-client`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/add-client`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding client ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post API to add employee  details =================================
export const addEmployee = async (values) => {
  const formData = new FormData();

  if(values.first_name){
    formData.append("first_name", values.first_name)
  }

  if(values.last_name){
    formData.append("last_name", values.last_name);
  }

  if(values.email){
    formData.append("email", values.email);
  }


  if(values.username){
    formData.append("username", values.username);
  }
  if(values.password){
    formData.append("password", values.password);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }
  if (values.address_2) {
    formData.append("address_2", values.address_2);
  }
  if (values.country) {
    formData.append("country", values.country);
  }
  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.dob) {
    formData.append("dob", parseDateString(values.dob));
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }
  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }
  if (values.city) {
    formData.append("city", values.city);
  }

  if (values.files) {
    const userImage = dataURLtoFile(values.files, "userImage");
    formData.append("files", userImage);
  }
  if (values.emp_designation) {
    formData.append("emp_designation", values.emp_designation);
  }
  if (values.emp_number) {
    formData.append("emp_number", values.emp_number);
  }
  if (values.website) {
    formData.append("website", values.website);
  }
  if (values.skype_id) {
    formData.append("skype_id", values.skype_id);
  }
  if (values.appointment_link) {
    formData.append("appointment_link", values.appointment_link);
  }
  formData.append("work_phone_number", values.work_phone_number);
  if (values.home_phone_number) {
    formData.append("home_phone_number", values.home_phone_number);
  }
  if (values.biography) {
    formData.append("biography", values.biography);
  }
  if (values.notes) {
    formData.append("notes", values.notes);
  }
  if (values.start_hired_date) {
    formData.append("start_hired_date", parseDateString(values.start_hired_date));
  }
  if (values.notes) {
    formData.append("notes", values.notes);
  }
  if (values.emp_type) {
    formData.append("emp_type", values.emp_type);
  }
  if (values.emp_category) {
    formData.append("emp_category", values.emp_category);
  }
  if (values.notes) {
    formData.append("notes", values.notes);
  }
  if (values.began_profession) {
    formData.append("began_profession", values.began_profession);
  }
  if (values.no_event_done) {
    formData.append("no_event_done", values.no_event_done);
  }
  if (values.emp_experience) {
    formData.append("emp_experience", values.emp_experience);
  }
  if (values.emp_pay_rate) {
    formData.append("emp_pay_rate", values.emp_pay_rate);
  }
  if (values.calculation_method) {
    formData.append("calculation_method", values.calculation_method);
  }
  if (values.hourly_rate) {
    formData.append("hourly_rate", values.hourly_rate);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/employee/add-employee`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/employee/add-employee`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding employee ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post API to add Time slot availability  details =================================
export const addSlotavailability = async (values) => {
  const formData = new FormData();

  if (values.id && values.type !== "all") {
    formData.append("id", values.id);
  }

  if (values.type) {
    formData.append("type", values.type);
  }

  if (values.days && values.days.length > 0) {
    formData.append("days", JSON.stringify(values.days));
    // formData.append("days", values.days);
  }

  if (values.startdate) {
    formData.append("event_start_date", values.startdate);
  }

  if (values.From) {
    formData.append("event_start_time", values.From);
  }

  if (values.enddate) {
    formData.append("event_end_date", values.enddate);
  }

  if (values.to) {
    formData.append("event_end_time", values.to);
  }

  if (values.occur) {
    formData.append("recurring", values.occur);
  }

  if (values.interval) {
    formData.append("interval", values.interval);
  }

  if (values.bymonth) {
    formData.append("bymonth", values.bymonth);
  }

  if (values.bymonthday) {
    formData.append("bymonthday", values.bymonthday);
  }

  if (values.byweekday) {
    formData.append("byweekday", values.byweekday);
  }
  // for (let [key, value] of formData.entries()) {
  //   console.log(`${key}: ${value}`);
  // }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/calendar/add-event-time-slot`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/calendar/add-event-time-slot`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while adding client ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

function dataURLtoFile1(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const checkLogin = async (values) => {
  const formData = new FormData();
  formData.append("username", values.username);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-companies`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An error occurred while adding venue");
    error.info = errorInfo;
    error.code = response.status;
    throw error;
  }
  const res = await response.json();
  return res;
};

export const setAuthTokenCookie = (token, username, email, role) => {
  const now = new Date();
  const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

  if (token) {
    Cookies.set("authToken", token, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("username", username, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("email", email, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("role", role, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.remove("login_superadmin", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }
};

export const checkEmailAvailability = async (value) => {
  const formData = new FormData();

  if(value.type == 'email'){
    formData.append("email", value.data);
  }

  if(value.type == 'username'){
    formData.append("username", value.data);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/check-username-or-email`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An error occurred while adding venue");
    error.info = errorInfo;
    error.code = response.status;
    throw error;
  }
  const res = await response.json();
  return res;
};