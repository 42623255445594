import React, { useContext, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie, getInitiatals } from "../../../../utils/Utils";
import Cookies from "js-cookie";
import { DataContext } from "../../../../context/DataProvider";
import profileimg from "../../../../../src/assets/images/profileimg.png";

const User = () => {
  const [profileName, setProfileName] = useState(getCookie("username", "John"));
  const { userProfile } = useContext(DataContext);
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const setLogout = () => {
    sessionStorage.removeItem("profileProgress");
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_ACCOUNT_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };

  const handleImageError = (e) => {
    e.target.src = profileimg; // Set fallback image if userProfile image fails to load
  };


  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle nk-quick-nav-icon"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        {/* <UserAvatar icon="user-alt" className="sm" /> */}
        <UserAvatar className="sm" />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter ">
          <div className="user-card sm">
            <div className="user-info" style={{ marginRight: "15px" }}>
              {userProfile || profileimg ? (
                <img
                  src={userProfile} // URL of the DJ's profile image
                  alt="Profile"
                  onError={handleImageError}
                  className="user-avatar"
                  style={{ width: "34px", height: "34px", borderRadius: "50%",  objectFit: "cover" }} // Ensure the image is rounded
                />
              ) : (
                <UserAvatar text={getInitiatals(profileName)} theme="primary" />
              )}
            </div>
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "mailto:john@silocloud.io")}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <LinkItem link="/" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem> */}
            {/* <LinkItem
              link={`${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`}
              icon="setting-alt"
              onClick={toggle}
            >
              Account Setting
            </LinkItem> */}
            {/* <LinkItem link="/" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem> */}
            <li>
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="#" icon="signout" onClick={setLogout}>
              Sign Out
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
