import React, { useContext, useEffect } from "react";
import { getEventsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { Col, Row } from "reactstrap";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { useLocation } from "react-router";
import NavigationHeader from "../components/NavigationHeader";
import profileimg from "../../assets/images/profileimg.png";
import { DataContext } from "../../context/DataProvider";

function PreviewEventDetails() {

  const { currency, setCurrency } = useContext(DataContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");

  const {
    data: eventsList,
    isLoading: eventsListLoading,
    isError: eventsListError,
    refetch: refetchEventdata,
  } = useQuery({
    queryKey: ["get-events-by-id", id],
    queryFn: () => getEventsList({ id: id, page: "", limit: "", search: "" }),
    staleTime: Infinity,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
  });

  useEffect(()=>{
    refetchEventdata();
  },[refetchEventdata])

  const client = eventsList?.data?.[0];

  function convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(":");
    const hours12 = (hours % 12) || 12; // Convert 24-hour time to 12-hour time
    const amPm = hours >= 12 ? "PM" : "AM";
    return `${hours12}:${minutes} ${amPm}`;
  }

  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Event Detail" : ""}
        buttonInnerText={"Back"}
        route={"/event-list"}
        back
      />
      {eventsListLoading ? (
        <Row className="py-0 m-4 border bg-white">
          {/* Left column with image */}
          {/* <Col md={6} className="border p-3 bg-white g-3"> */}
          {/* <Row> */}
          <Col xs={12} md={2} className=" p-3 bg-white g-3  text-center">
            {/* Image */}
            <ShimmerThumbnail height={150} width={150} rounded={false} />
          </Col>
          <Col xs={12} md={4} className=" bg-white p-3 g-1">
            <Row className="mt-2">
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <ShimmerText height={20} width={150} />
          </Col>
          {/* </Row> */}
          {/* </Col> */}

          {/* Right column with details */}
          <Col xs={12} md={6} className="border-start p-3 bg-white g-3">
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <ShimmerText height={20} width={150} />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col xs={12} md={2} className=" p-3 bg-white g-3  text-center">
              {/* <Col xs={12} md={6} className="text-start"> */}
              {/* Image */}
              <img
                src={
                  client?.client_details[0]?.profile_image_path ===
                  client?.client_details[0]?.profile_image_path?.startsWith("https")
                    ? client?.client_details[0]?.profile_image_path
                    : profileimg
                } // Replace with the actual image field
                alt="Package Image"
                style={{ width: "150px", height: "150px", borderRadius: "0%", objectFit: "cover" }}
              />
              {/* </Col> */}
            </Col>
            <Col xs={12} md={4} className=" bg-white p-3 g-3">
              <Row >
                <Col xs={6} md={5}>
                  <strong>Client First Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.client_details[0]?.first_name || "N/A"}
                </Col>
              </Row>
              <Row >
                <Col xs={6} md={5}>
                  <strong>Client Last Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.client_details[0]?.last_name || "N/A"}
                </Col>
              </Row>
              <Row >
                <Col xs={6} md={5}>
                  <strong>Client Username :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.client_details[0]?.username || "N/A"}
                </Col>
              </Row>
              <Row >
                <Col xs={6} md={5}>
                  <strong>Client Email :</strong>
                </Col>
                <Col xs={6} md={7} className="text-break">
                  {client?.client_details[0]?.email || "N/A"}
                </Col>
              </Row>
              <Row >
                <Col xs={6} md={5}>
                  <strong>Client Work Number :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.client_details[0]?.work_phone_number || "N/A"}
                </Col>
              </Row>
              <Row >
                <Col xs={6} md={5}>
                  <strong>Event Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.event_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Event Type :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.event_type_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Attire :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.attire || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Guest Count :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.guest_count || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Event Date :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.start_date_time?.split(" ")[0] || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Setup Time :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.setup_time || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Start Time :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.start_date_time?.split(" ")[1] ? convertTo12HourFormat(client?.start_date_time?.split(" ")[1])  : "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>End Time :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.end_date_time?.split(" ")[0] ? convertTo12HourFormat(client?.end_date_time?.split(" ")[1])  : "N/A"}
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col xs={12} md={6} className="border-start p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={5}>
                  <strong>Selected venue :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.venue_details[0]?.venue_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Package Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.package_details[0]?.package_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Package Price :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.package_details[0]?.package_price ? `${currency}` : ""} {client?.package_details[0]?.package_price || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Retainer value :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.package_details[0]?.package_deposite_price ? `${currency}` : ""} {client?.package_details[0]?.package_deposite_price || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Balance due after retainer :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.event_financials?.balance_due_after_retainer ? `${currency}` : ""} {client?.event_financials?.balance_due_after_retainer || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Overtime rate (Hourly) :</strong>
                </Col>
                <Col xs={6} md={7}>
                 {client?.package_details[0]?.package_overtime ? `${currency}` : ""} {client?.package_details[0]?.package_overtime || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Scheduled Payment - 2 Date :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.scheduled_payment_2?.split(" ")[0] || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Scheduled Payment - 2 Price :</strong>
                </Col>
                <Col xs={6} md={7}>
                 {client?.scheduled_payment_2_value ? `${currency}` : ""} {client?.scheduled_payment_2_value || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Scheduled Payment - 3 Date :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.scheduled_payment_3?.split(" ")[0] || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Scheduled Payment - 3 Price :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.scheduled_payment_3_value ? `${currency}` : ""} {client?.scheduled_payment_3_value || "N/A"}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewEventDetails;
