import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  ModalHeader,
  Row,
  Col,
  Popover,
  PopoverBody,
} from "reactstrap";
import { Icon, ReactDataTable } from "../../components/Component";
import Swal from "sweetalert2";
import TableActionBar from "../components/TableActionBar";
import ReactPlayer from "react-player";
import { deleteMixeUrl, deleteyoutublink } from "../../http/delete/deleteApi";
import { useMutation, useQueryClient } from "react-query";
import { DataContext } from "../../context/DataProvider";
import { Link, useNavigate } from "react-router-dom";
import subscribeimg from "../../../src/assets/images/subscribe.jpg";
import { useTheme } from "../../layout/provider/Theme";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

function UploadMixesUrl({
  formik,
  isCompanyProfileEditing,
  youtubLinkLimit,
  youtubelinkCount,
  subscribedpalresponce,
  styles,
}) {
  const [localLinks, setLocalLinks] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMixId, setCurrentMixId] = useState("");

  const { hasUnsavedChanges, setHasUnsavedChanges, planEnddates, activeTab, setActivetab } = useContext(DataContext);

  const queryClient = useQueryClient();

  const availableOptions = [
    { value: "soundcloud", label: "SoundCloud" },
    { value: "mixcloud", label: "MixCloud" },
  ];

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const popoverRef = useRef(null);

  const { skin } = useTheme();

  const customStyles = {
    // Style the container
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff",  // Example dark mode background
      color: "black", // Change text color
      borderColor: state.isFocused ? "#60CF4F" : "#444",  // Change border color when focused
      boxShadow: state.isFocused ? "0 0 0 1px #60CF4F" : null,  // Highlight border on focus
    }),
    // Style for each option in the dropdown
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: state.isSelected ? "#ffff" : "#000000", // Change text color for selected and hovered options
      padding: 10,
    }),
    // Style for the dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Background of dropdown
    }),
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      // border: "1px solid #384d69",
      borderColor: _state.isFocused ? "#60CF4F" : "#60CF4F",
      boxShadow: _state.isFocused ? "0 0 0 1px #60CF4F" : null,
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      // backgroundColor: "#141c26",
      backgroundColor: _state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : _state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: _state.isSelected ? "#ffff" :  _state.isFocused ? "#000000" : "#fff", 
      // color: "white",
      border: "1px solid #384d69",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#141c26", // Background of dropdown
    }),
  };

  const [selectedCategoryLabels, setSelectedCategoryLabels] = useState("soundcloud");

  const [formikdata, setFormikdata] = useState(formik);
  const navigate = useNavigate();
  // console.log("formikdata",formikdata)

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (!formik.values.mixtitle) {
      toast.warning("Title Field is required")
    }
    if (!formik.values.mixlink) {
      toast.warning("Link Field is required")
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== "" 
    ) {
      callbackFun(true);
    }else{
      callbackFun(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen]);

  // const [soundCloudUrl, setSoundCloudUrl] = useState("");
  const openMixModal = (mixId) => {
    // setSoundCloudUrl(mixId)
    // Use a regular expression to extract the iframe
    const iframeMatch = mixId.match(/<iframe[^>]*>(.*?)<\/iframe>/i);

    // Check if the iframe tag is found
    if (iframeMatch && iframeMatch[0]) {
      const extractedIframe = iframeMatch[0];
      // console.log("Extracted Iframe:", extractedIframe);

      // Set the extracted iframe in the state or do further processing
      setCurrentMixId(extractedIframe);
    } else {
      console.log("No iframe found");
    }

    toggleModal();
  };

  

  const handleAddMixLink = () => {
  
    
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    if (!subscribedpalresponce) {
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add more youtubeLinks to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {
      if (true) {
        handleValidation((validation) => {
          if(validation === false){
            setActivetab("1")
            navigate("/Company-profile/edit-profile")
          }else if (validation === true) {
            
            if (formik.values.mixtitle && formik.values.mixlink) {
              const newlinks = {
                index: formik.values.mixes_links.length + 1,
                mixtitle: formik.values?.mixtitle,
                mixlink: formik.values?.mixlink,
                mixesType: formik.values?.mixesType,
              };
    
              // Ensure vendors is an array
              const mixLinksArray = Array.isArray(formik.values?.mixes_links) ? formik.values.mixes_links : [];
    
              formik.setValues({
                ...formik.values,
                mixes_links: [...mixLinksArray, newlinks],
                mixtitle: "",
                mixlink: "",
              });
              formik.submitForm();
            }

          }
        })
        
      } else {
        Swal.fire({
          title: "MixesLink Limit Exceeded!",
          text: `You've reached the limit of ${youtubLinkLimit} for your youtubelink. Upgrade your plan to add more youtubelinks and make your business stand out!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("4");
          }
        });
      }
    }
  };

  const {
    mutate: deleteMixUrl,
    isLoading: islinkDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-mixurl-by-id"],
    mutationFn: (data) => deleteMixeUrl(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-company-profile");
      }
    },
  });
  const handleRemoveMixLink = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMixUrl(id);
          // eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your mix_link has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting mix_link:", error);
    }
  };

  const handleMixLink = (e) => {
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const tableHeadings = [
    {
      name: "Sr. No.",
      selector: (row) => row.id,
      style: {
        paddingLeft: "30px",
        paddingRight: "10px",
      },
    },
    {
      name: "Title",
      selector: (row) => row.mixtitle,
    },
    // {
    //   name: "Mix Link ID",
    //   selector: (row) => row.mixlink,
    // },
    {
      name: "Action",
      // style: {
      //   display: "flex",
      //   justifyContent: "center",
      // },
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="play-circle"
            options1TooleTipText="View"
            options1onClick={() => openMixModal(row.mixlink)}
            options2Icon="ni ni-trash"
            options2TooleTipText="Delete"
            options2onClick={() => handleRemoveMixLink(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <div className="tab-content">
      <div>
        <Card>
          <CardBody className="custom-card">
            <div style={{ textAlign: "center" }}>{islinkDeleting ? <Spinner color="dark" /> : ""}</div>

            {/* <CardTitle style={{fontWeight:'bold'}}>Add Mixes Link</CardTitle> */}
            <Row className="g-2">
              <Col md={2}>
                <ReactSelect
                  id="mixesType"
                  name="mixesType"
                  options={availableOptions}
                  // value={selectedCategoryLabels}
                  // onChange={handleOptions}

                  value={availableOptions?.find((option) => option?.value === formik.values?.mixesType)}
                  // onChange={(selectedOption) => setSelectedCategoryLabels(selectedOption?.value)}
                  onChange={(selectedOption) => formik.setFieldValue("mixesType",selectedOption.value)}
                  // menuIsOpen={isCategoryMenuOpen}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
                />
              </Col>
              <Col md={3}>
                <Input
                  type="text"
                  name="mixtitle"
                  placeholder="Enter Track Title"
                  value={formik.values.mixtitle || ""}
                  // onChange={formik.handleChange}
                  onChange={handleMixLink}
                  style={{ marginRight: "20px" }}
                />
              </Col>
              <Col md={5}>
                <Input
                  type="text"
                  name="mixlink"
                  // maxLength={50}
                  placeholder="Enter Embedded Code"
                  value={formik.values.mixlink || ""}
                  // onChange={formik.handleChange}
                  onChange={handleMixLink}
                  style={{ marginRight: "20px" }}
                />
              </Col>
              <Col md={1} sm="3">
                <div
                  className="nk-quick-nav-icon"
                  style={{ alignItems: "center", marginTop: "5px", cursor: "pointer" }}
                  id="Popoverinfo"
                >
                  <Icon name="info" className="size-md" style={{ fontSize: "1.2rem", position: "relative" }} />
                </div>
              </Col>
              <Col md={1} sm="3">
                <Button color="primary" onClick={handleAddMixLink} className="ml-5">
                  <Icon name="plus-sm" style={{ fontSize: "20px" }} />
                </Button>
              </Col>
            </Row>

            {/* <div className="form-control-wrap d-flex align-items-center" style={{ width: "60%", marginLeft: "18%" }}>
              <Input
                type="text"
                name="mixtitle"
                placeholder="Enter Mixes link title"
                value={formik.values.mixtitle || ""}
                // onChange={formik.handleChange}
                onChange={handleMixLink}
                style={{ marginRight: "20px" }}
              />
              <Input
                type="text"
                name="mixlink"
                // maxLength={50}
                placeholder="Enter Mixes Link"
                value={formik.values.mixlink || ""}
                // onChange={formik.handleChange}
                onChange={handleMixLink}
                style={{ marginRight: "20px" }}
              />
              <Button
                // disabled={!(youtubLinkLimit > youtubelinkCount)}
                color="primary"
                onClick={handleAddMixLink}
                className="ml-5"
              >
                <Icon name="plus-sm" style={{ fontSize: "20px" }} />
              </Button>
            </div> */}
            <div className="text-center mt-3"></div>
          </CardBody>
        </Card>
      </div>
      <div className="pt-2 pb-0">
        {/* <h5>Mixes Link</h5> */}
        <section className="mt-4 border p-3 bg-white">
          <ReactDataTable
            data={formik.values.mixes_links}
            columns={tableHeadings}
            pagination
            actions
            searchBy="mixtitle"
            pageNo={setPageNo}
            recordLimit={setRecordLimit}
            totalRecords={formik.values.mixes_links.length}
            setSearch={setSearch}
          />
        </section>
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader
          toggle={toggleModal}
          close={
            <button className="close" onClick={toggleModal}>
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader>
        <ModalBody className="p-0 h-200px">
          <div className="iframe-container" dangerouslySetInnerHTML={{ __html: currentMixId }} />
          {/* {soundCloudUrl && (
            <ReactPlayer
              url={soundCloudUrl} // Pass the URL directly as a string
              controls
              className="react-player"
              width="100%"
              height="100%"
            />
          )} */}
        </ModalBody>
      </Modal>

      <Popover
        placement="bottom"
        innerRef={popoverRef}
        isOpen={popoverOpen}
        toggle={togglePopover}
        target="Popoverinfo"
        style={{ width: "400px", maxWidth: "100%" }}
      >
        <PopoverBody style={{ width: "100%", padding: "0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1px",
              width: "100%",
              minWidth: "100%",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <ol style={{ width: "100%", padding: "10px", margin: "0" }}>
              <li>1. Go to your Sound Track</li>
              <li>2. Share → Embedded → copy (Iframe code)</li>
              <li>3. Paste in "Enter Iframe Text box"</li>
            </ol>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}

export default UploadMixesUrl;
