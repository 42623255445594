import React from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";

const PaymentDetailsModal = ({ isOpen, toggle, clientdetails, eventfinancials }) => {
  // Function to format date as needed
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust locale or format as needed
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Payment Details</ModalHeader>
      <ModalBody>
        <div style={{marginBottom:'20px'}}>
        <span><span style={{fontWeight:'bold'}}>Name :     </span>{ `${clientdetails?.first_name || ""} ${clientdetails?.last_name || ""}`}</span><br/>
        <span><span style={{fontWeight:'bold'}}>Mobile No. :     </span>{!clientdetails?.work_phone_number ? `${clientdetails?.work_phone_number || "Not Provided"}`: "Not Provided" }</span><br/>
        <span><span style={{fontWeight:'bold'}}>Email :     </span><a href={`mailto:${clientdetails?.email}`}>{clientdetails?.email || ""}</a></span><br/>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '8px'}}>Payment Type</th>
              <th style={{ textAlign: 'left', padding: '8px'}}>Amount</th>
              <th style={{ textAlign: 'left', padding: '8px'}}>Transaction ID</th>
              <th style={{ textAlign: 'left', padding: '8px'}}>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Retainer</td>
              <td>{eventfinancials?.retainer_value ? eventfinancials?.retainer_value : "- "}</td>
              <td>{eventfinancials?.retainer_txn ? eventfinancials?.retainer_txn : "- "}</td> {/* Placeholder for Transaction ID, adjust as needed */}
              <td>{formatDate(eventfinancials?.updated_at) ? formatDate(eventfinancials?.updated_at) : "- "}</td> {/* Display formatted date */}
            </tr>
            <tr>
              <td>scheduled Payment 2</td>
              <td>{eventfinancials?.scheduled_payment_2 ? eventfinancials?.scheduled_payment_2 : "- "}</td>
              <td>{eventfinancials?.scheduled_payment_2_txn ? eventfinancials?.scheduled_payment_2_txn : "-"}</td> {/* Placeholder for Transaction ID, adjust as needed */}
              <td>{formatDate(eventfinancials?.updated_at) ? formatDate(eventfinancials?.updated_at) : "-"}</td> {/* Display formatted date */}
            </tr>
            <tr>
              <td>scheduled Payment 3</td>
              <td>{eventfinancials?.scheduled_payment_3 ? eventfinancials?.scheduled_payment_3 : "- "}</td>
              <td>{eventfinancials?.scheduled_payment_3_txn ? eventfinancials?.scheduled_payment_3_txn : "-"}</td> {/* Placeholder for Transaction ID, adjust as needed */}
              <td>{formatDate(eventfinancials?.updated_at) ? formatDate(eventfinancials?.updated_at) : "- "}</td> {/* Display formatted date */}
            </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default PaymentDetailsModal;
