import React, { useState, useContext, useEffect } from "react";
import SlideA from "../../images/slides/slide-a.jpg";
import SlideB from "../../images/slides/slide-b.jpg";
import SlideC from "../../images/slides/slide-c.jpg";
import {
  UserAvatar,
  Icon,
  LinkList,
  LinkItem,
} from "../../components/Component";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { findUpper, getInitiatals } from "../../utils/Utils";
import { ChatContext } from "./ChatContext";
import ShareContactModal from "../components/ShareContactModal";
import {
  deleteChatRoom,
  removeChattingId,
  updateUserVisibility,
} from "../../Firebase Files/firebase-service";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import fallbackVideoImg from "../../assets/images/jsTree/videopause.jpg";

const ChatSideBar = ({
  sidebar,
  chat,
  currentUser,
  setSelectedId,
  sharedPhotos,
}) => {
  const {
    changeNickname,
    changeTheme,
    userData,
    addUserToChat,
    deleteUser,
    makeAdmin,
  } = useContext(ChatContext);
  const [memberState, setMemberState] = useState(false);
  const [optionState, setOptionState] = useState(false);
  const [settingState, setSettingState] = useState(false);
  const [photoState, setPhotoState] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePhotos, setImagePhotos] = useState([]);
  useEffect(() => {
    // Filter for image URLs and update the imagePhotos state
    const mediaFiles = sharedPhotos.filter((url) => {
      const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
      return [
        "jpg",
        "png",
        "jpeg",
        "gif",
        "avif",
        "mp4",
        "webm",
        "ogg",
      ].includes(fileType);
    });

    setImagePhotos(mediaFiles);
  }, [sharedPhotos]); // This effect runs whenever sharedPhotos changes

  // Function to toggle the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // const slides = [
  //   {
  //     id: 0,
  //     img: SlideA,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  //   {
  //     id: 1,
  //     img: SlideB,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  //   {
  //     id: 2,
  //     img: SlideC,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  //   {
  //     id: 3,
  //     img: SlideC,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  //   {
  //     id: 4,
  //     img: SlideC,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  //   {
  //     id: 5,
  //     img: SlideC,
  //     title: "Slide A",
  //     content: "This is slide A",
  //   },
  // ];

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const toggleModal1 = () => {
    setIsModalOpen1(!isModalOpen1);
  };

  const openModal1 = (name, id) => {
    setSelectedContact({ name, id });
    toggleModal1();
  };

  const confirmDelete1 = () => {
    if (selectedContact) {
      handleDeleteContact(selectedContact.name, selectedContact.id);
    }
    toggleModal1();
  };

  const handleDeleteContact = (name, id) => {
    // Call your deletion functions here
    // updateUserVisibility(`${name}_${id.toString()}`, false);
    removeChattingId(`${name}_${id}`, currentUser?.id);
    setSelectedId(null);
    deleteChatRoom(currentUser?.id, id);
  };

  const [isModalOpenShowPhoto, setIsModalOpenShowPhoto] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const MAX_MEDIA_DISPLAY = 4;

  const toggleModalShowPhoto = () =>
    setIsModalOpenShowPhoto(!isModalOpenShowPhoto);

  const handleMediaClick = (index) => {
    setCurrentMediaIndex(index);
    toggleModalShowPhoto();
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      const extension = url.split(".").pop().split("?")[0];
      const filename = `downloaded-file.${extension}`;

      link.href = objectUrl;
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };
  return (
    <>
      <SimpleBar className={`nk-chat-profile ${sidebar ? "visible" : ""}`}>
        <div className="user-card user-card-s2 my-4">
          {chat?.profilePic ? (
            <img
              src={chat?.profilePic} // URL of the DJ's profile image
              alt="Profile"
              className="user-avatar"
              style={{ width: "45px", height: "45px", borderRadius: "50%" }} // Ensure the image is rounded
            />
          ) : (
            <UserAvatar
              text={getInitiatals(chat?.name)}
              theme="primary"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            ></UserAvatar>
          )}
          <div className="user-info">
            <h5>{chat?.nickname ? chat?.nickname : chat?.name}</h5>
            {/* <span className="sub-text">Active {chat?.active === true ? "now" : `${chat?.active} ago`} </span> */}
          </div>
          {/* <UncontrolledDropdown className="user-card-menu">
            <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <LinkList opt className="no-bdr">
                <LinkItem link={"/user-details-regular/1"} icon="eye">
                  View Profile
                </LinkItem>
                <LinkItem link={""} tag="a" icon="na">
                  Block From System
                </LinkItem>
              </LinkList>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
        <div className="chat-profile">
          {/* <div className="chat-profile-group">
          <a
            href="#options"
            onClick={(ev) => {
              ev.preventDefault();
              setOptionState(!optionState);
            }}
            className={`chat-profile-head`}
            id="chat-options"
          >
            <h6 className="title overline-title">Options</h6>
            <span className="indicator-icon">
              <Icon name={`chevron-${optionState ? "up" : "down"}`}></Icon>
            </span>
          </a>
          <div className={`chat-profile-body collapse ${optionState ? "" : "show"}`} id="chat-options">
            <div className="chat-profile-body-inner">
              <ul className="chat-profile-options">
                <li>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="#"
                      color="transparent"
                      className="chat-option-link"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon className="icon icon-circle bg-light ni ni-edit-alt"></Icon>
                      <span className="lead-text">{chat.group || chat.chatGroup ? "Group Name" : "Nickname"}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-tidy no-bdr p-3">
                        <input
                          className="form-control mb-2"
                          type="text"
                          placeholder={chat.group || chat.chatGroup ? "Group Name" : "Nickname"}
                          onChange={(e) => changeNickname(chat.id, e.target.value)}
                        />
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      color="transparent"
                      className="chat-option-link"
                      href="#dropdown"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="circle-fill" className={`icon-circle bg-light text-${chat.chatTheme}`}></Icon>
                      <span className="lead-text">Change Theme</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-list-plain li-col3x text-center">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            className="bg-blue"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "blue");
                            }}
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "purple");
                            }}
                            className="bg-purple"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "warning");
                            }}
                            className="bg-warning"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "success");
                            }}
                            className="bg-success"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "danger");
                            }}
                            className="bg-danger"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "info");
                            }}
                            className="bg-info"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "pink");
                            }}
                            className="bg-pink"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "orange");
                            }}
                            className="bg-orange"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              changeTheme(chat.id, "secondary");
                            }}
                            className="bg-secondary"
                            style={{ height: "25px" }}
                          ></DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
          {/* .chat-profile-group */}
          {chat.group && (
            <div className="chat-profile-group">
              <a
                href="#member"
                onClick={(ev) => {
                  ev.preventDefault();
                  setMemberState(!memberState);
                }}
                className="chat-profile-head"
              >
                <h6 className="title overline-title">Members</h6>
                <span className="indicator-icon">
                  <Icon name={`chevron-${memberState ? "up" : "down"}`}></Icon>
                </span>
              </a>
              <div
                className={`chat-profile-body collapse ${
                  memberState ? "" : "show"
                }`}
                id="chat-photos"
              >
                <div className="chat-profile-body-inner">
                  <ul className="chat-members">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          color="transparent"
                          className="chat-members-link add-opt"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon
                            name="plus"
                            className="icon-circle bg-light"
                          ></Icon>
                          <span className="sub-text">Add Member</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-plain">
                            {userData.map((user, key) => {
                              return (
                                <li key={key}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      addUserToChat(chat.id, user);
                                    }}
                                  >
                                    <UserAvatar
                                      text={findUpper(user.name)}
                                      theme={user.theme}
                                      className="xs"
                                      image={null}
                                    />
                                    <div className="user-name">{user.name}</div>
                                  </DropdownItem>
                                </li>
                              );
                            })}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>

                    <li>
                      {chat.user.map((user, idx) => {
                        return (
                          <div className="user-card" key={idx}>
                            <a
                              href="#card"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              <UserAvatar
                                size="sm"
                                theme={user.theme}
                                image={user.profilePic}
                                text={findUpper(user.name)}
                              ></UserAvatar>
                              <div className="user-name">{user.name}</div>
                              <div className="user-role">
                                {user.role && user.role}
                              </div>
                            </a>
                            <div className="user-actions">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deleteUser(chat.id, user.id);
                                        }}
                                        href="#delete"
                                      >
                                        Remove
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          makeAdmin(chat.id, user.id);
                                        }}
                                        href="#make-admin"
                                      >
                                        Make Admin
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {/* <div className="chat-profile-group">
          <a
            href="#settings"
            onClick={(ev) => {
              ev.preventDefault();
              setSettingState(!settingState);
            }}
            className="chat-profile-head"
          >
            <h6 className="title overline-title">Settings</h6>
            <span className="indicator-icon">
              <Icon name={`chevron-${settingState ? "up" : "down"}`}></Icon>
            </span>
          </a>
          <div className={`chat-profile-body collapse ${settingState ? "" : "show"}`} id="chat-settings">
            <div className="chat-profile-body-inner">
              <ul className="chat-profile-settings">
                <li>
                  <div className="custom-control custom-control-sm custom-switch">
                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                    <label className="custom-control-label" htmlFor="customSwitch2">
                      Notifications
                    </label>
                  </div>
                </li>
                <li>
                  <a
                    className="chat-option-link"
                    href="#ignore"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon className="icon icon-circle bg-light ni ni-bell-off-fill"></Icon>
                    <div>
                      <span className="lead-text">Ignore Messages</span>
                      <span className="sub-text">You won’t be notified when message you.</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="chat-option-link"
                    href="#feedback"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon className="icon icon-circle bg-light ni ni-alert-fill"></Icon>
                    <div>
                      <span className="lead-text">Something Wrong</span>
                      <span className="sub-text">Give feedback and report conversion.</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
          {/* .chat-profile-group */}
          {/* <div className="chat-profile-group">
            <a
              href="#shared"
              onClick={(ev) => {
                ev.preventDefault();
                setPhotoState(!photoState);
              }}
              className="chat-profile-head"
            >
              <h6 className="title overline-title">Shared Photos</h6>
              <span className="indicator-icon">
                <Icon name={`chevron-${photoState ? "up" : "down"}`}></Icon>
              </span>
            </a>
            <div className={`chat-profile-body collapse ${photoState ? "" : "show"}`} id="chat-photos">
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-media">
                  {imagePhotos.map((slide, index) => (
                    <li key={index} onClick={() => handleMediaClick(index)}>
                      <a
                        href="#media"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        {slide.includes(".mp4") || slide.includes(".webm") || slide.includes(".ogg") ? (
                          // Show fallback image for videos
                          <img src={fallbackVideoImg} alt={`Video thumbnail ${index + 1}`} height={35} width={"100%"} />
                        ) : (
                          // Show image directly for photos
                          <img src={slide} alt={`Shared Photo ${index + 1}`} height={50} />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          {/* .chat-profile-group */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {/* Share Contact */}
            <Button
              color="link"
              style={{
                textDecoration: "none",
                color: "#000",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
              onClick={toggleModal} // Open the modal on button click
            >
              <Icon name="user-fill"></Icon>
              <span>Share Contact</span>
            </Button>

            {/* Delete Contact */}
            <Button
              color="link"
              style={{
                textDecoration: "none",
                color: "#ff0000",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
              // onClick={() => handleDeleteContact(chat?.name, chat?.id)}
              onClick={() => openModal1(chat?.name, chat?.id)}
              disabled={chat?.id === currentUser?.id}
            >
              <Icon name="trash"></Icon>
              <span>Delete Contact</span>
            </Button>
          </div>
        </div>
      </SimpleBar>

      <ConfirmDeleteModal
        isOpen={isModalOpen1}
        toggle={toggleModal1}
        onConfirm={confirmDelete1}
      />

      {/* ShareContactModal Component */}
      <ShareContactModal
        isOpen={isModalOpen} // Pass the state to control modal visibility
        toggle={toggleModal} // Pass the toggle function to close the modal
        shareContact={chat} // Pass the current user prop if needed
        currentUser={currentUser}
      />

      {/* Modal for Image/Video Display */}
      <Modal
        isOpen={isModalOpenShowPhoto}
        toggle={toggleModalShowPhoto}
        centered
        size="lg"
        className="media-modal"
      >
        <ModalHeader
          toggle={toggleModalShowPhoto}
          className="modal-header-custom d-flex justify-content-between modal-header-content"
        >
          <span>Media Gallery</span>
        </ModalHeader>
        <ModalBody className="media-modal-body">
          <Button
            color="primary"
            onClick={() => handleDownload(imagePhotos[currentMediaIndex])}
            className="download-button"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              height: "fit-content",
            }}
          >
            <Icon name={"download"} />
          </Button>
          <div className="media-container">
            {imagePhotos.map((url, index) => {
              const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
              const isImage = ["jpg", "png", "jpeg", "gif", "avif"].includes(
                fileType
              );

              if (index === currentMediaIndex) {
                return (
                  <div key={index} className="media-content">
                    {isImage ? (
                      <img src={url} alt="media" className="media-display" />
                    ) : (
                      <video src={url} controls className="media-display" />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>

          {/* Navigation Buttons */}
          <div className="navigation-buttons">
            <Button
              color="secondary"
              className="nav-button left"
              onClick={() => setCurrentMediaIndex((prev) => prev - 1)}
              disabled={currentMediaIndex === 0}
            >
              &#8249;
            </Button>
            <Button
              color="secondary"
              className="nav-button right"
              onClick={() => setCurrentMediaIndex((prev) => prev + 1)}
              disabled={currentMediaIndex === imagePhotos?.length - 1}
            >
              &#8250;
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ChatSideBar;
