import React, { useContext, useEffect, useMemo, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Routes, Route, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { Button } from "reactstrap";
import { getCookie } from "../../../utils/Utils";
import { DataContext } from "../../../context/DataProvider";

// const stripePromise = loadStripe(
//   "pk_test_51QA9qFBNxoKCw9k9ighgIrz8vUBwVMv1W9eHajwxHl3E5UbthGv3Vj99hlCHbgwBNky6ZS8vi17vyba85OQRQznr00HQZ3VYm4"
// );


function StripePayment({ data, price, selectedPlan, setModalData, invalidPlanData, setModal, modal, refetchPlandata, setModalTab, modalTab, client_key}) {
  const { transactionid, setTransactionId, newPlanId, setNewPlanId } = useContext(DataContext);
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  const navigate = useNavigate();
  // const stripePromise = loadStripe(client_key);
  const stripePromise = useMemo(() => loadStripe(client_key), [client_key]);
  
  const handleBuyNow = () => {
    setNewPlanId(data.id)
    fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/stripe-plan-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken"),
      },
      body: JSON.stringify({ id: data.id }),
      // body: JSON.stringify({ items: [{ id: data.id }] }),
      // body: JSON.stringify({ items: [{ id: data.id, amount: price }] }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setDpmCheckerLink(data.dpmCheckerLink);
        // setTransactionId(data.transactionid);
      })
      .catch((error) => {
        console.error("Error creating payment intent:", error);
      });
  };

  useEffect(() => {
    handleBuyNow();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const loader = "auto";

  return (
    <>
      {/* <div className="plan-price-container">
        <span className="plan-name">Plan: {data.name}</span>
        <span className="plan-price">Price: ${price}</span>
      </div> */}

      <div className="App">
        {clientSecret && (
          <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
            <CheckoutForm dpmCheckerLink={dpmCheckerLink} selectedPlan={selectedPlan} setModalData={setModalData} invalidPlanData={invalidPlanData}
            setModal={setModal}
            modal={modal}
            fetchPlanData={refetchPlandata}
            setModalTab={setModalTab}
            modalTab={modalTab}
            />
          </Elements>
        )}
      </div>
    </>
  );
}

export default StripePayment;
