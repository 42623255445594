import React, { useContext, useEffect, useState } from "react";
import { Icon, UserAvatar } from "../../components/Component";
import SimpleBar from "simplebar-react";
import { Input, Button } from "reactstrap";
import { ChatItem, ChatItemSearch } from "./ChatPartials";
import { findUpper } from "../../utils/Utils";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import NoData from "../../assets/images/NoData.png";

export const ChatAsideBody = ({
  onInputChange,
  setSelectedId,
  selectedId,
  filterTab,
  chatItemClick,
  filteredChatList,
  currentUser,
  messages,
  filterText,
  loading,
  searchData,
}) => {
  // console.log("finalMappedList:", filteredChatList);

  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              value={filterText}
              placeholder="Search By Name"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">{filterTab}</h6>
        <ul className="chat-list">
          {loading ? (
            [1, 2, 3, 4, 5].map((item, idx) => (
              <ShimmerCategoryItem
                key={idx}
                hasImage
                imageType="circular"
                imageWidth={60}
                imageHeight={60}
                text
              />
            ))
          ) : searchData !== null && searchData.length > 0 ? (
            searchData?.map((item, idx) => {
              {
                console.log("yes, no chats 111");
              }
              // if (filterTab === "messages") {
              return (
                <ChatItemSearch
                  key={idx}
                  item={item}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  chatItemClick={chatItemClick}
                  currentUser={currentUser}
                  messages={messages}
                  searchData={searchData}
                ></ChatItemSearch>
              );
              // }
            })
          ) : searchData === null && filteredChatList.length ? (
            filteredChatList?.map((item, idx) => {
              // if (filteredChatList) {
              return (
                <ChatItem
                  key={idx}
                  item={item}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  chatItemClick={chatItemClick}
                  currentUser={currentUser}
                  messages={messages}
                  searchData={searchData}
                ></ChatItem>
              );
              // }
            })
          ) : filteredChatList.length === 0 ? (
            <>
              <div className="flex-column justify-center align-center mt-5">
                <div className="h-50 w-50">
                  <div>
                    <img src={NoData} alt="" />
                  </div>
                  <div className="lead-text text-center mt-2">No Chats Yet</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex-column justify-center align-center mt-5">
                {console.log("yes, no chats 444")}
                <div className="h-50 w-50">
                  <div>
                    <img src={NoData} alt="" />
                  </div>
                  <div className="lead-text text-center mt-2">No Chats Yet</div>
                </div>
              </div>
            </>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};
