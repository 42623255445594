import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import logo from "../../images/favicon.png";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import CalendarModal from "../../pages/EventCalendar/CalendarModal";
import { Icon } from "../../components/Component";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

import {
  Button,
  Card,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import { DataContext } from "../../context/DataProvider";
import { color } from "framer-motion";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useQuery } from "react-query";
import { getsitesetting } from "../../http/get/getApi";
import { checkLogin, setAuthTokenCookie } from "../../http/post/postApi";
import ChatNotification from "./dropdown/chat notification/ChatNotification";

const handleLoginClick = async () => {
  const username = "super_admin";
  try {
    const response = await checkLogin({ username });

    const authToken = response.data.authToken;
    const email = response.data.email;
    const role = response.data.role.id;
    console.log("authToken", authToken);
    if (authToken) {
      setAuthTokenCookie(authToken, username, email, role);
      const url = `${process.env.REACT_APP_ADMIN_URL}`;
      window.open(url, "_blank");
      console.log("Login successful and authToken set in cookie");
    } else {
      console.warn("Auth token is missing, login not completed");
    }
  } catch (error) {
    console.error("Error:", error.message, error.info);
  }
};
export const SlideCard = ({
  slide,
  onNavigate,
  closePopover,
  handleNextClick,
  handleBackClick,
  currentSlide,
  modalSlides,
}) => {
  return (
    <>
      <Card
        className="h-150px"
        style={{
          // border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "10px",
          // margin: "8px",
          // backgroundColor: "#f9f9f9",
          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          minWidth: "95%",
          display: "ruby",
        }}
      >
        <div className="h-100 align-content-around">
          {/* <h5>{slide.title}</h5> */}
          <CardText className="text-body mb-0" style={{ fontSize: "12px", height: "80px" }}>
            {slide?.description}
          </CardText>
          <div
            className="mt-0 d-flex justify-content-between align-center w-100"
            style={{ width: "100%", padding: "1px" }}
          >
            <div>
              <Button
                onClick={() => {
                  onNavigate();
                  closePopover(); // Close the popover
                }}
                style={{
                  background: "#6b7a95",
                  padding: "4px 8px",
                  color: "white",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  width: "fit-content",
                  display: "inline-block",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.background =
                    "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
                }
                onMouseOut={(e) => (e.currentTarget.style.background = "#6b7a95")}
              >
                {slide?.title}
              </Button>
            </div>
            <div>
              <Button
                className="btn btn-icon btn-trigger me-n1 ml-2"
                onClick={handleBackClick}
                disabled={currentSlide === 0}
                style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}
              >
                <Icon name="caret-left" className="size-md sub-text ml" style={{ fontSize: "1.5rem" }} />
              </Button>
              <Button
                className="btn btn-icon btn-trigger me-n1"
                onClick={handleNextClick}
                disabled={currentSlide === modalSlides.length - 1}
                style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}
              >
                <Icon name="caret-right" className="size-md sub-text" style={{ fontSize: "1.5rem" }} />
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const AppHeader = ({ fixed, className, app }) => {
  const useExampleContext = useContext(DataContext);
  const {
    profileprogress,
    activeTab,
    setActivetab,
    profileCompletion,
    setGetEventData,
    setTransformSlotdata,
    currency,
    setCurrency,
  } = useExampleContext;

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const navigate = useNavigate();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const popoverRef = useRef(null);
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const loginSuperadmin = Cookies.get("login_superadmin");

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Profile Progress</Popover.Header>
      <Popover.Body>
        {/* Content of the popover */}
        <p>Here is the profile progress...</p>
        {/* Add your profile progress details here */}
      </Popover.Body>
    </Popover>
  );

  const modalSlides = [
    {
      title: "Add Basic Information",
      description:
        " Adding DJ profile Basic Information can boost client engagement and potentially increase bookings by up to 5%",
      section: "Company-profile/edit-profile",
      displayText: "Add Basic Information",
      tabNumber: "1",
      key: "basicinfo", // Corresponding key in the completionStatus object
    },
    {
      title: "Add description",
      description:
        " Adding DJ profile description can boost client engagement and potentially increase bookings by up to 5%",
      section: "Company-profile/edit-profile",
      displayText: "Add Description",
      tabNumber: "2",
      key: "about",
    },
    {
      title: "Add Services",
      description:
        "Boost your event's success and client engagement by adding our professional DJ services. Our expertise can increase bookings by up to 20%, ensuring unforgettable experiences that leave a lasting impression.",
      section: "Company-profile/edit-profile",
      displayText: "Add Services",
      tabNumber: "3",
      key: "services",
    },
    {
      title: "Add Mixes",
      description:
        "Upload your best mixes to demonstrate your unique style and creativity. Let your music speak for you! DJs who upload their mixes see up to 20% more bookings.",
      section: "Company-profile/edit-profile",
      displayText: "Add Mixes",
      tabNumber: "4",
      key: "mixres",
    },
    {
      title: "Add Images",
      description:
        "Showcase your projects by adding images to highlight your work. Let your visuals and performances speak for you! DJs who add multimedia to their profiles see up to 20% more bookings.",
      section: "Company-profile/edit-profile",
      displayText: "Add Gallery & Video",
      tabNumber: "5",
      key: "images",
    },
    {
      title: "Videos & YoutubeLink",
      description:
        "Showcase your projects by adding videos and YoutubeLink to highlight your work. Let your visuals and performances speak for you! DJs who add multimedia to their profiles see up to 20% more bookings.",
      section: "Company-profile/edit-profile",
      displayText: "Add Gallery & Video",
      tabNumber: "6",
      key: "videos",
    },
    {
      title: "Add Social Media",
      description:
        "Include links to your social media profiles to connect with your audience and expand your reach. Show off your online presence and let your followers discover your talent! DJs who link their social media see up to 10% more bookings.",
      section: "Company-profile/edit-profile",
      displayText: "Add Social Media",
      tabNumber: "7",
      key: "social_media",
    },
    {
      title: "Buy Premium Plan to get more dsicount",
      description:
        "Upgrade to our Premium Plan for greater visibility and up to 20% more bookings! Stand out to potential clients with priority placement and advanced features designed to boost your DJ career.",
      section: "/plan-selection",
      displayText: "Buy Premium Plan",
      tabNumber: "8",
      key: "Premium Plan",
    },
  ];

  // const incompleteSlides = modalSlides.filter((slide) => profileCompletion[slide.key] < weights[slide.key]);
  const profileCompletionBoolean = {};
  for (const key in profileCompletion) {
    profileCompletionBoolean[key] = profileCompletion[key] === 1 ? true : false;
  }

  const incompleteSlides = modalSlides?.filter((slide) => !profileCompletionBoolean[slide?.key]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen]);

  const handleNextClick = () => {
    if (currentSlide < modalSlides.length - 1) {
      const nextSlide = currentSlide + 1;
      setActivetab(modalSlides[nextSlide].tabNumber); // Set active tab based on the next slide
      setCurrentSlide(nextSlide);
    }
  };

  const handleBackClick = () => {
    if (currentSlide > 0) {
      const prevSlide = currentSlide - 1;
      setActivetab(modalSlides[prevSlide].tabNumber); // Set active tab based on the previous slide
      setCurrentSlide(prevSlide);
    }
  };

  const toggleModal = () => setModal(!modal);

  const handleInfoClick = () => {
    setModalContent("Hello");
    setModal(true);
  };

  const blockCount = 5;
  const blockPercentage = 20;

  const filledBlocks = Math.floor(profileprogress / blockPercentage);
  const partialBlockWidth = ((profileprogress % blockPercentage) / blockPercentage) * 100;

  const percentage = 66;

  const {
    data: companyDetails,
    isLoading: companyprofileLoading,
    isError: companyprofileError,
  } = useQuery({
    queryKey: ["get-sitesetting"],
    queryFn: () => getsitesetting("currency"),
    staleTime: Infinity,
    // staleTime: 90000,
    onSuccess: (data) => {
      setCurrency(data?.other?.currency);
    },
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap d-flex justify-between">
          {/* <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div> */}
          <div className="nk-header-app-name">
            {/* <div className="nk-header-app-logo">
              <img src={logo} alt="DJ_logo" />
            </div> */}
            <div className="nk-header-app-info">
              <span className="lead-text text-primary" style={{ fontSize: "20px" }}>
                DJ
              </span>
              <span className="lead-text">{app.text}</span>
            </div>
          </div>

          <div
            className="shadow leaflet-bar w-25 d-none d-sm-block m-1"
            style={{
              textAlign: "center",
              // width: "30%",
              // margin: "10px 20% 10px 30%",
              border: "2px solid #cfd2f1",
              borderRadius: "8px",
            }}
          >
            {/* <span style={{ marginTop: "10px", display: "block" }}>{progress}%</span> */}
            {profileprogress < 90 && (
              <CardText
                className="lead-text"
                style={{ fontSize: "0.9rem", textAlign: "center", marginBottom: "1px", marginTop: "4px" }}
              >
                Complete your profile.
              </CardText>
            )}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "200px",
                  margin: "0 10px",
                }}
              >
                {[...Array(blockCount)].map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: "18%", // Ensure the blocks fit within the container, with space for gaps
                      height: "5px",
                      borderRadius: "10px",
                      justifyContent: "space-between",
                      gap: "1",
                      backgroundColor: index < filledBlocks ? "#60cf4f" : "#d3d3d3",
                      position: "relative",
                    }}
                  >
                    {index === filledBlocks && profileprogress % blockPercentage !== 0 && (
                      <div
                        style={{
                          width: `${partialBlockWidth}%`,
                          backgroundColor: "#60cf4f",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                        }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="nk-quick-nav-icon"
                style={{ alignItems: "center", marginTop: "5px", cursor: "pointer" }}
                id="PopoverBottom"
              >
                <Icon name="info" className="size-md" style={{ fontSize: "1.2rem", position: "relative" }} />
              </div>
            </div>
          </div>

          <div className="nk-header-tools m-0">
            <ul className="nk-quick-nav">
              {loginSuperadmin === "1" && (
                <li onClick={() => handleLoginClick()}>
                  <p>
                    <a style={{ textDecoration: "none", color: "inherit" }}>Login As Admin</a>
                  </p>
                </li>
              )}
              {/* <li onClick={() => navigate("chat")} className="nk-quick-nav-icon">
                <Icon name="chat" className="size-md" style={{ fontSize: "1.5rem", cursor: "pointer" }} />
              </li> */}

              <li onClick={() => navigate("chat")} className="nk-quick-nav-icon">
                <ChatNotification />
              </li>
              <li onClick={() => navigate("calendar")} className="nk-quick-nav-icon">
                <Icon name="calendar-alt" className="size-md" style={{ fontSize: "1.5rem", cursor: "pointer" }} />
              </li>
              <li className="notification-dropdown me-n1">
                <Notification />
              </li>
              <li className="list-apps-dropdown d-lg-none">
                <AppDropdown />
              </li>
              {/* <li className="user-dropdown">
                <User />
              </li> */}
              <li>
                <div className="user-dropdown-wrapper">
                  <div className="progress-container">
                    <CircularProgressbar
                      className="d-lg-none d-md-none"
                      value={profileprogress}
                      text={`${profileprogress}%`}
                      styles={{
                        root: { width: "80px", height: "80px" }, // Adjust size as needed
                        path: { stroke: "#60cf4f" },
                        text: { fill: "#4db8ff", fontSize: "16px" },
                      }}
                    />
                  </div>
                  <div className="user-container mt-0">
                    <User />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Profile Completion Guide</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
            {/* Render only the current slide */}
            <SlideCard
              slide={modalSlides[currentSlide]}
              // onNavigate={() => navigate(`/${modalSlides[currentSlide].section}`)}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <button
            onClick={handleBackClick}
            disabled={currentSlide === 0}
            style={{ backgroundColor: "transparent", border: "none", cursor: "pointer", padding: "0 10px" }}
          >
            <Icon name="caret-left" className="size-md" style={{ fontSize: "1.5rem" }} />
          </button>
          <button
            onClick={handleNextClick}
            disabled={currentSlide === modalSlides.length - 1}
            style={{ backgroundColor: "transparent", border: "none", cursor: "pointer", padding: "0 10px" }}
          >
            <Icon name="caret-right" className="size-md" style={{ fontSize: "1.5rem" }} />
          </button>
        </ModalFooter>
      </Modal>

      <Popover
        placement="bottom"
        innerRef={popoverRef}
        isOpen={popoverOpen}
        toggle={togglePopover}
        target="PopoverBottom"
        style={{ width: "400px", maxWidth: "100%" }}
      >
        <PopoverBody style={{ width: "100%", padding: "0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1px",
              width: "100%",
              minWidth: "100%",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            {/* Render only the current slide */}

            <SlideCard
              slide={incompleteSlides[currentSlide]}
              onNavigate={() => {
                navigate(`${incompleteSlides[currentSlide].section}`);
                setActivetab(incompleteSlides[currentSlide].tabNumber); // Set active tab when navigating
              }}
              closePopover={() => setPopoverOpen(false)}
              style={{ width: "100%" }}
              handleBackClick={handleBackClick}
              handleNextClick={handleNextClick}
              currentSlide={currentSlide}
              modalSlides={incompleteSlides}
            />
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};
export default AppHeader;
