import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { getCookie } from "../utils/Utils";

const ProtectedRoutes = () => {
  const [isAllowed, setIsAllowed] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const end_date = data?.data?.package?.end_date;

        if (!end_date) {
          navigate("/plan-selection");
        } else {
          const currentDate = new Date();
          const endDate = new Date(end_date);

          if (endDate < currentDate) {
            navigate("/plan-selection");
          } else {
            setIsAllowed(true);
          }
        }
      } catch (error) {
        console.error("Error checking access:", error);
        navigate("/plan-selection");
      }
    };

    checkAccess();
  }, [navigate]);

  //   if (isAllowed === null) {
  //     return <div>Loading...</div>;
  //   }

  return <Outlet />;
};

export default ProtectedRoutes;
