import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "../pages/Home Page Setup/Homepage";
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";
import ProjectCardPage from "../pages/prebuilt/projects/ProjectCard";
import LogoChange from "../pages/prebuilt/Logo Separation/LogoChange";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import AddEvent from "../pages/event/AddEvent";
import PhotoGraphyEvent from "../pages/Photography/Sidebar/PhotoGraphyEvent";
import Trending from "../pages/Trending";
import Albums from "../pages/Albums";
import Artists from "../pages/Artists";
import ClientListPage from "../pages/Client/ClientListPage";
import CreateClientPage from "../pages/Client/CreateClientPage";
import EmployeeListPage from "../pages/Employee/EmployeeListPage";
import CreateEmployeePage from "../pages/Employee/CreateEmployeePage";
import VenueListPage from "../pages/Venue/VenueListPage";
import CreateVenuePage from "../pages/Venue/CreateVenuePage";
import PackageListPage from "../pages/Package/PackageListPage";
import CreatePackagePage from "../pages/Package/CreatePackagePage";
import VenderListPage from "../pages/Vender/VenderListPage";
import CreateVenderPage from "../pages/Vender/CreateVenderPage";
import UserProfileRegular from "../pages/prebuilt/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/prebuilt/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/prebuilt/user-manage/UserProfileNotification";
import CompanyInfo from "../pages/prebuilt/Company List/CompanyInfo";
import PlanRegistration from "../pages/prebuilt/Registration Plans/PlanRegistration";
import CreateServiceProfile from "../pages/Profile/createServiceProfile";
import EnquiryList from "../pages/DjEnquiryList/EnquiryList";
import UserProfile from "../pages/Profile/UserProfile";
import SuspendPage from "../pages/error/suspendPage";
import MonthlyandYearlyPlan from "../pages/prebuilt/Registration Plans/MonthlyandYearlyPlan";
import CalenderComponent from "../pages/EventCalendar/CalenderComponent";
import PreviewClient from "../pages/Client/PreviewClient";
import PreviewEmployeeDetails from "../pages/Employee/PreviewEmployeeDetails";
import PreviewVenueDetails from "../pages/Venue/PreviewVenueDetails";
import PreviewVendorDetails from "../pages/Vender/PreviewVendorDetails";
import PreviewPackageDetails from "../pages/Package/PreviewPackageDetails";
import PreviewEventDetails from "../pages/EventList/PreviewEventDetails";
import CommonEvent from "../pages/EventList/CommonEvent";
import Invoicedetails from "../pages/prebuilt/invoice/InvoiceDetails";
import ConstructionEvents from "../pages/Construction/ConstructionEvents";
import InvoicePrint from "../pages/prebuilt/invoice/InvoicePrint";
import CheckoutForm from "../pages/prebuilt/Registration Plans/CheckoutForm";
import ChatContainer from "../pages/Chat Files/ChatContainer";
import ProtectedRoutes from "./ProtectedRoutes";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={
          <Layout
            app={{
              icon: "dashboard",
              theme: "bg-purple-dim",
              text: "Dashboard",
            }}
          />
        }
      >
        <Route path="plan-selection" element={<MonthlyandYearlyPlan />}></Route>

        <Route index element={<Homepage />}></Route>
        <Route element={<ProtectedRoutes />}>
          <Route path="dashboard" element={<Homepage />}></Route>
          <Route path="Trending" element={<Trending />}></Route>
          <Route path="Albums" element={<Albums />}></Route>
          <Route path="Artists" element={<Artists />}></Route>
          <Route path="add-event" element={<AddEvent />}></Route>
          {/* photography */}

          <Route path="add-photography-event" element={<PhotoGraphyEvent />}>
            {/* construction */}
          </Route>
          <Route
            path="add-construction-event"
            element={<ConstructionEvents />}
          ></Route>
          <Route path="suspend" element={<SuspendPage />}></Route>

          <Route path="event-list">
            <Route index element={<CommonEvent />} />
            <Route path="add-event" element={<AddEvent />} />
            <Route path="add-event/preview" element={<PreviewEventDetails />} />
            <Route path=":id" element={<AddEvent />} />
            <Route
              path="invoice-detail/:event_id"
              element={<Invoicedetails />}
            />
            <Route
              path="invoice-print/:event_id"
              element={<InvoicePrint />}
            ></Route>
          </Route>
          <Route path="chat" element={<ChatContainer />} />

          <Route path="calendar">
            <Route index element={<CalenderComponent />} />
          </Route>

          <Route path="/" element={<CheckoutForm />} />

          {/* pre built pages  */}
          <Route path="project-card" element={<ProjectCardPage />}></Route>
          <Route path="site-setting" element={<LogoChange />}></Route>
          <Route path="company-list" element={<CompanyInfo />}></Route>

          <Route path="client-list">
            <Route index element={<ClientListPage />} />
            <Route path="create-client" element={<CreateClientPage />} />
            <Route path="create-client/preview" element={<PreviewClient />} />
            <Route path=":id" element={<CreateClientPage />} />
          </Route>

          <Route path="employee-list">
            <Route index element={<EmployeeListPage />} />
            <Route path="create-employee" element={<CreateEmployeePage />} />
            <Route
              path="create-employee/preview"
              element={<PreviewEmployeeDetails />}
            />
            <Route path=":id" element={<CreateEmployeePage />} />
          </Route>

          <Route path="venue-list">
            <Route index element={<VenueListPage />} />
            <Route path="create-venue" element={<CreateVenuePage />} />
            <Route
              path="create-venue/preview"
              element={<PreviewVenueDetails />}
            />
            <Route path=":id" element={<CreateVenuePage />} />
          </Route>

          <Route path="vendor-list">
            <Route index element={<VenderListPage />} />
            <Route path="create-vender" element={<CreateVenderPage />} />
            <Route
              path="create-vender/preview"
              element={<PreviewVendorDetails />}
            />
            <Route path=":id" element={<CreateVenderPage />} />
          </Route>

          <Route path="package-list">
            <Route index element={<PackageListPage />} />
            <Route path="create-package" element={<CreatePackagePage />} />
            <Route
              path="create-package/preview"
              element={<PreviewPackageDetails />}
            />
            <Route path=":id" element={<CreatePackagePage />} />
          </Route>

          <Route path="inquiry-list">
            <Route index element={<EnquiryList />} />
            <Route path="create-package" element={<CreatePackagePage />} />
            <Route path=":id" element={<CreatePackagePage />} />
          </Route>
        </Route>

        <Route>
          <Route
            path="user-profile-notification"
            element={<UserProfileNotification />}
          ></Route>
          <Route
            path="user-profile-regular"
            element={<UserProfileRegular />}
          ></Route>
          <Route
            path="user-profile-setting"
            element={<UserProfileSetting />}
          ></Route>
        </Route>
        <Route path="Company-profile">
          <Route index element={<UserProfile />} />
          <Route path="edit-profile" element={<CreateServiceProfile />} />
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
