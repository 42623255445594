import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import EClient from "./EClient";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Icon } from "../../components/Component";
import Head from "../../layout/head/Head";
import EDetails from "./EDetails";
import EFinancials from "./EFinancials";
import EVenue from "./EVenue";
import EBooking from "./EBooking";
import EStaff from "./EStaff";
import EResources from "./EResources";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import NavigationHeader from "../components/NavigationHeader";
import { getClientsList, getEventsList } from "../../http/get/getApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addEventDetails } from "../../http/post/postApi";
import { editEventDetails } from "../../http/edit/editApi";
import { sendSMS } from "../../http/SMS/sms";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getChangedValues } from "../../utils/Utils";
import { DataContext } from "../../context/DataProvider";

const AddEvent = () => {
  // navigation declrations
  const { activeTab, setActivetab } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  //getting details from the URl
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  // const [activeTab, setActivetab] = useState("1");
  const [eventId, setEventId] = useState(null);
  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;

  // functions used to naviugate between pages
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };


  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  //VALIDATIONS SCHEMAS AS PER THE ERACH TAB
  const schemaTab1 = Yup.object().shape({
    client_id: Yup.string().required("Client field is required."),
  });

  const schemaTab2 = Yup.object().shape({
    event_name: Yup.string().required("Event Name field is required."),
    event_type: Yup.string().required("Event Type field is required."),
    eventDate: Yup.string().required("Event Date field is required."),
    startTime: Yup.string().required("Start time field is required."),
    end_date_time: Yup.string().required("End time field is required."),
  });

  const getValidationSchema = (activeTab) => {
    switch (activeTab) {
      case "1":
        return schemaTab1;
      case "2":
        return schemaTab2;
      default:
        return Yup.object();
    }
  };

  let initialValues = {
    client_id: "",
    event_name: "",
    event_type_name: "",
    event_type: "",
    eventDate: "",
    startTime: "",
    attire: "",
    guest_count: "",
    setup_time: "",
    end_date_time: "",
    package_name: "",
    package_id: "",
    package_price: 0,
    addons: [],
    addon_name: "",
    quantity: "",
    price: "",
    add_on_total: 0,
    total: 0,
    balanceDue: 0,
    retainer_value: 0,
    package_overtime: "",
    overtimeHours: "",
    overtimeRateTotal: 0,
    travel_cost: "",
    discount1: "",
    discount2: "",
    scheduled_payment_2: "",
    scheduled_payment_2_value: "",
    scheduled_payment_3: "",
    scheduled_payment_3_value: "",
    city: "",
    venue_id: "",
    secondary_venue_id: "",
    booking_status: "",
    next_action: "",
    next_action_date: "",
    intial_contract_date: "",
    contract_sent_date: "",
    contract_due_date: "",
    booked_date: "",
    inquiry_source: "",
    sales_person_id: "",
    rfi_form_id: "",
    instant_quote_id: "",
    internal_notes_booking: "",
    internal_notes_resources: "",
    notes3: "",
    employees: [],
    secondary_venue_id: "",
    username: "",
    timeDiff: "",
    package_duration: "",
  };

  //use effct to store and get data from localstorage
  useEffect(() => {
    const storedData = localStorage.getItem("EventformData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      formik.setValues(parsedData);
    }
  }, []);

  //fetching event data from id
  const {
    data: eventsList,
    isLoading: eventsListLoading,
    isError: eventsListError,
  } = useQuery({
    queryKey: ["get-events-by-id", id],
    queryFn: () => getEventsList({ id: id, page: "", limit: "", search: "" }),
    // staleTime: 50000,
    staleTime: Infinity,
    cacheTime: 300000, 
    retry: 1,
  });

  //Mutation hook for adding event
  const {
    mutate: addNewEvent,
    isLoading: isEventAdding,
    error: eventAddError,
  } = useMutation({
    mutationKey: ["Add-event"],
    mutationFn: (values) => addEventDetails(values),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        navigate(`/add-event?type=edit&id=${data.data.id}`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
      localStorage.removeItem("EventformData");
      queryClient.invalidateQueries(["get-events-list"]);
    },
  });
  const addEvent = (values) => {
    try {
      addNewEvent(values);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  //Mutation hook for edit
  const {
    mutate: editEventMutation,
    isLoading: isEventEditing,
    error: eventEditError,
  } = useMutation({
    mutationKey: ["Edit-event-by-ID"],
    mutationFn: (value) => editEventDetails(value, id),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        queryClient.invalidateQueries(["get-events-by-id"]);

        setHasInvalidated(true);
        if (activeTab === "5") {
          if (from === "fromEdit") {
            console.log("testing event update toster")
            // navigate("/event-list");
            toast.success("Event Updated Succesfully");
            formik.resetForm();
            formik.setFieldValue("client_id", null);
          } else {
            console.log("testing event add toster")
            toast.success("Event Added Succesfully");
            formik.resetForm();
            formik.setFieldValue("client_id", null);
            // navigate("/event-list");
          }
        }
      } 
      else {
        Swal.fire({
          icon: "success",
          title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
      queryClient.invalidateQueries(["get-events-list"]);
      
    },
  });
  const editEventFromList = (value) => {
    try {
      editEventMutation(value);
    } catch (error) {
      console.error("Error editing event:", error);
    }
  };
  const companyEmail = Cookies.get("email");

  // formic defining
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(activeTab),
    onSubmit: (values) => {
      if (!isEditOnly) {
        addEvent(values);
        if (activeTab === "5") {
          const number = clientInfo?.data?.clients?.[0]?.work_phone_number;
          const message = `Congratulations! Your ${eventName} registration is confirmed. For any queries, contact us at ${companyEmail}. Thank you!`;
          sendSMS(number, message);
          formik.resetForm();
        }
      } else if (isEditOnly || eventsList?.data) {
        // editEventFromList(values);
        editEventFromList(getChangedValues(eventsList?.data[0], formik.values));
      }
    },
  });

  const eventName = formik.values.event_name;
  //get client from selected clientd id
  const ClientId = formik.values.client_id;

  const {
    data: clientInfo,
    isLoading: clientInfoLoading,
    isError: clientInfoError,
    // refetch: refetchClientList,
  } = useQuery({
    queryKey: ["get-selected-client-id", ClientId],
    queryFn: () => getClientsList({ id: ClientId, page: "", limit: "", search: "" }),
    // enabled: false,
    staleTime: Infinity,
    // staleTime: 50000,
    // cacheTime: 300000, 
    // retry: 1,
  });

  // useEffect(()=>{
  //   refetchClientList();
  // },[])

  const clientAddress = clientInfo?.data?.clients?.[0]?.city;

  // Save form data to localStorage whenever form values change
  useEffect(() => {
    localStorage.setItem("EventformData", JSON.stringify(formik.values));
  }, [formik.values]);

  //to set the values from the api when form is opened in view or edit type
  useEffect(() => {
    if (isEditOnly && !eventsListLoading || isViewOnly) {
      formik.setValues((prevData) => {
        const totalAddOn = addOnTotalValue || 0;
        return {
          ...prevData,
          ...eventsList?.data[0],
          package_price: eventsList?.data[0]?.package_details[0]?.package_price || "",
          package_duration: eventsList?.data[0]?.package_details[0]?.package_duration || "",
          package_overtime: eventsList?.data[0]?.package_details[0]?.package_overtime || "",
          eventDate: new Date(eventsList?.data[0]?.start_date_time),
          startTime: new Date(eventsList?.data[0]?.start_date_time),
          end_date_time: new Date(eventsList?.data[0]?.end_date_time),
          travel_cost: eventsList?.data[0]?.event_financials?.travel_fee || "",
          add_on_total: totalAddOn,
          addons: eventsList?.data[0]?.addons ? eventsList?.data[0]?.addons : [],
          retainer_value: eventsList?.data[0]?.retainer_value,
          scheduled_payment_2: eventsList?.data[0]?.scheduled_payment_2,
          scheduled_payment_2_value : eventsList?.data[0]?.scheduled_payment_2_value,
          scheduled_payment_3: eventsList?.data[0]?.scheduled_payment_3,
          scheduled_payment_3_value: eventsList?.data[0]?.scheduled_payment_3_value,
          // employees: eventsList?.data[0]?.employee_details,
          employees: eventsList?.data[0]?.emp_details ? eventsList?.data[0]?.emp_details : [],
          city: eventsList?.data[0]?.venue_details[0]?.city,
          discount1: eventsList?.data[0]?.event_financials?.discount_1 || 0,
          discount2: eventsList?.data[0]?.event_financials?.discount_2 || 0,
        };
      });
    }
  }, [isEditOnly, eventsListLoading]);

  //function to get total addOnTotal of the specific event
  function addOntotalValue() {
    let addOnTotal = 0;
    const addons = eventsList?.data?.[0]?.addons;

    if (addons) {
      for (let i = 0; i < addons.length; i++) {
        addOnTotal += addons[i]?.price * addons[i]?.quantity;
      }
    }
    return addOnTotal;
  }

  const addOnTotalValue = addOntotalValue() || 0;

  // console.log("event formik data", formik.values)

  // rendering components
  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <NavigationHeader
        heading={from === "fromEdit" ? "Edit Event" : from === "fromView" ? "Event Details" : "Add Event"}
        buttonInnerText={"Event List"}
        route={"/event-list"}
        removeIcon={true}
      />
      <div class="card card-bordered card-preview m-4">
        <div className="card-inner pt-1">
          <Nav tabs>
            <NavItem>
              { isViewOnly ? "" :

              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                <Icon name="user" /> <span>Client</span>
              </NavLink>
              }
            </NavItem>
            <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                <Icon name="layers" /> <span>Details</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "3" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("3");
                }}
              >
                <Icon name="location" /> <span>Venue</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "4" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("4");
                }}
              >
                <Icon name="sign-dollar" /> <span> Financials</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "5" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("5");
                }}
              >
                <Icon name="calendar-booking" /> <span>Booking</span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "5" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("5");
                }}
              >
                <Icon name="view-group-wd" /> <span>Staff</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "7" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("7");
                }}
              >
                <Icon name="folder" /> <span>Resources</span>
              </NavLink>
            </NavItem> */}
          </Nav>
          <form onSubmit={formik.handleSubmit} className="mt-3">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <EClient
                  toggleMain={toggle}
                  mainCurrentTab={activeTab}
                  formik={formik}
                  eventsListLoading={eventsListLoading}
                  isViewOnly={isViewOnly}
                  isEventAdding={isEventAdding}
                  isEventEditing={isEventEditing}
                />
              </TabPane>
              <TabPane tabId="2">
                <EDetails
                  toggleMain={toggle}
                  mainCurrentTab={activeTab}
                  formik={formik}
                  eventsListLoading={eventsListLoading}
                  isEditOnly={isEditOnly}
                  isEventAdding={isEventAdding}
                  isEventEditing={isEventEditing}
                  isViewOnly={isViewOnly}
                />
              </TabPane>
              <TabPane tabId="3">
                <EVenue toggleMain={toggle} mainCurrentTab={activeTab} formik={formik} clientAddress={clientAddress} isViewOnly={isViewOnly} isEventEditing={isEventEditing} isEventAdding={isEventAdding}/>
              </TabPane>
              <TabPane tabId="4">
                <EFinancials
                  eventsList={eventsList}
                  toggleMain={toggle}
                  mainCurrentTab={activeTab}
                  formik={formik}
                  isEditOnly={isEditOnly}
                  isViewOnly={isViewOnly}
                  isEventEditing={isEventEditing}
                />
              </TabPane>
              {/* <TabPane tabId="5">
                <EBooking toggleMain={toggle} mainCurrentTab={activeTab} formik={formik} isViewOnly={isViewOnly} isEventEditing={isEventEditing}/>
              </TabPane> */}
              <TabPane tabId="5">
                <EStaff toggleMain={toggle} mainCurrentTab={activeTab} formik={formik} isViewOnly={isViewOnly} isEventEditing={isEventEditing}/>
              </TabPane>
              {/* <TabPane tabId="7">
                <EResources
                  toggleMain={toggle}
                  mainCurrentTab={activeTab}
                  formik={formik}
                  isEventAdding={isEventAdding}
                  isEventEditing={isEventEditing}
                  isViewOnly={isViewOnly}
                />
              </TabPane> */}
            </TabContent>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEvent;
