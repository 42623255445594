import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  arrayRemove,
} from "firebase/firestore";
import { db, storage } from "./firebaseConfig"; // Assuming you have db initialized from firebase
import { getDownloadURL, ref } from "firebase/storage";

export const updateUserStatus = async (
  userId,
  status,
  avatarUrl,
  newConvoItem
) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    await updateDoc(userRef, {
      active: status,
      profilePic: avatarUrl, // Add/update the profilePic field
      convo: arrayUnion(newConvoItem), // Add the new convo item to the convo array
    });
    console.log(`User ${userId} status updated, and convo added`);
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

export const updateUserVisibility = async (userId, visibility) => {
  console.log("Updating visibility for user:", userId, "to", visibility);
  try {
    const userRef = doc(db, "Users", userId.toString());

    // Check if the user exists first
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      console.log("User does not exist, can't update visibility.");
      return;
    }

    // Update user visibility
    await updateDoc(userRef, {
      visible: visibility,
    });

    console.log(`User ${userId} visibility updated to ${visibility}`);
  } catch (error) {
    console.error("Error updating user visibility:", error);
  }
};

export const updateUserConvo = async (userId, newConvoItem) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    console.log(userId);
    console.log(userRef);
    await updateDoc(userRef, {
      convo: arrayUnion(newConvoItem), // Add the new convo item to the convo array
    });
    console.log(`User ${userId} status updated, and convo added`);
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

// Function to delete the chatroom between the current user and a contact
export const deleteChatRoom = async (currentUserId, contactId) => {
  console.log("currentUserId:", currentUserId);
  console.log("contactId:", contactId);

  if (!currentUserId || !contactId) {
    console.error("Invalid user or contact ID");
    return;
  }

  // Query the Chats collection to find a chat that includes both participants
  const q = query(
    collection(db, "Chats"),
    where("participants", "array-contains", currentUserId)
  );

  const querySnapshot = await getDocs(q);
  let chatId = null;

  querySnapshot.forEach((doc) => {
    const participants = doc.data().participants;
    if (participants.includes(contactId)) {
      chatId = doc.id; // Capture the chat ID for deletion
    }
  });

  // Delete the chatroom if it exists
  if (chatId) {
    try {
      await deleteDoc(doc(db, "Chats", chatId));
      console.log(`Chatroom with ID ${chatId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting chatroom:", error);
    }
  } else {
    console.log("No chatroom found between the current user and the contact.");
  }
};

export const updateChattingIds = async (userId, chatId) => {
  try {
    const userRef = doc(db, "Users", userId.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Check if chattingIds array exists and if chatId is already present
      if (!userData.chattingIds || !userData.chattingIds.includes(chatId)) {
        await updateDoc(userRef, {
          chattingIds: arrayUnion(chatId), // Add the new chatId if not present
        });
        console.log(`User ${userId} status updated, and chatId added`);
      } else {
        console.log(
          `chatId ${chatId} already exists in chattingIds for user ${userId}`
        );
      }
    } else {
      console.log(`User ${userId} does not exist.`);
    }
  } catch (error) {
    console.error("Error updating user status:", error);
  }
};

export const removeChattingId = async (userId, chatId) => {
  console.log("chatId:", chatId);
  console.log("userId:", userId);

  try {
    const userRef = doc(db, "Users", userId.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Check if chattingIds array exists and if chatId is present
      if (userData.chattingIds && userData.chattingIds.includes(chatId)) {
        await updateDoc(userRef, {
          chattingIds: arrayRemove(chatId), // Remove the specified chatId if present
        });
        console.log(
          `chatId ${chatId} removed from chattingIds for user ${userId}`
        );
      } else {
        console.log(
          `chatId ${chatId} not found in chattingIds for user ${userId}`
        );
      }
    } else {
      console.log(`User ${userId} does not exist.`);
    }
  } catch (error) {
    console.error("Error removing chatId:", error);
  }
};

export const checkUserExists = async (userId) => {
  const userDoc = doc(db, "Users", userId); // Adjust based on your structure
  const docSnap = await getDoc(userDoc);
  return docSnap.exists();
};

export const handleDownloadFile = async (filePath, userId) => {
  // Check if the user exists in the Users collection
  const userDoc = doc(db, "Users", userId);
  const docSnap = await getDoc(userDoc);

  if (!docSnap.exists()) {
    console.log("User does not exist in Users collection!");
    return null; // User does not exist, abort download
  }

  // User exists, proceed to get the download URL
  const fileRef = ref(storage, filePath);

  try {
    const downloadURL = await getDownloadURL(fileRef);
    console.log("Download URL:", downloadURL);
    return downloadURL; // Return the download URL
  } catch (error) {
    console.error("Error fetching download URL:", error);
    return null; // Handle the error as needed
  }
};
