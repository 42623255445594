import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import NavigationHeader from "../components/NavigationHeader";
import Business from "../components/CreateVederPageForm/Business";
import Home from "../components/CreateVederPageForm/Home";
import Other from "../components/CreateVederPageForm/Other";
import Personal from "../components/CreateVederPageForm/Personal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router";
import { addVender } from "../../http/post/postApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getVenderList } from "../../http/get/getApi";
import { editVenderDetails } from "../../http/edit/editApi";
import { PlanContext } from "../context/PlanContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getChangedValues } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";

const CreateVenderPage = () => {
  const { planState, setPlanState } = useContext(PlanContext);
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");

  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;
  const [activeTab, setActivetab] = useState("1");

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const toggle = (number) => {
    setActivetab(number);
  };

  const schema = Yup.object().shape({
    // username: Yup.string().required("Username field is required."),
    // password: isEditOnly
    //   ? Yup.string()
    //       .nullable()
    //       .min(8, "Password must be at least 8 characters.")
    //       .matches(
    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //         "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
    //       )
    //   : Yup.string()
    //       .required("Password is required.")
    //       .min(8, "Password must be at least 8 characters.")
    //       .matches(
    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //         "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
    //       ),
    // first_name: Yup.string().required("First name field is required."),
    // last_name: Yup.string().required("Last name field is required."),

    // country: Yup.string().required("country field is required."),
    // state: Yup.string().required("state field is required."),
    // city: Yup.string().required("city field is required."),
    // business_country: Yup.string().required("business_country field is required."),
    // business_state: Yup.string().required("business_state field is required."),
    // business_city: Yup.string().required("business_city field is required."),

    email: Yup.string().required("Email field is required.").email("Invalid email address."),
    business_phone: Yup.string()
      .required("Business phone no field is required.")
      .matches(/^[0-9]{9,13}$/, "business phone no must be between 9 to 13 digits."),
    phone_number: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, "Work phone no must be between 9 to 13 digits."),
    cell_phone: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, "Cell phone no must be between 9 to 13 digits."),
    business_zip_code: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]{5,10}$/, "Zipcode must be between 5 to 10 characters."),
    zip_code: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]{5,6}$/, "Zipcode must be betwen 5 to 6 digits."),
    website: Yup.string()
      .nullable()
      .matches(
        /^(https?:\/\/)(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})([\/\w .-]*)*\/?$/,
        "Please enter a valid website URL (must start with http or https)."
      ),
  });

  let initialValues = {
    username: "",
    first_name: "",
    middleName: "",
    last_name: "",
    company_name: "",
    files: "",
    email: "",
    password: "",
    website: "",
    business_phone: "",
    business_fax: "",
    business_address: "",
    business_city: "",
    business_zip_code: "",
    business_country: "",
    business_state: "",
    phone_number: "",
    fax_phone: "",
    cell_phone: "",
    address_1: "",
    city: "",
    zip_code: "",
    country: "",
    state: "",
    birthday: "",
    spouse: "",
    anniversary: "",
    date_added_to_system: "",
    is_this_vendor: "",
    notes: "",
    prefer_vendor: "",
    is_owner: "",
    representative_name: "",
    first_nameCount: "",
    last_nameCount: "",
    company_nameCount: "",
    websiteCount: "",
    business_phoneCount: "",
    business_faxCount: "",
    business_addressCount: "",
    business_zip_codeCount: "",
    representative_nameCount: "",
    phone_numberCount: "",
    fax_phoneCount: "",
    cell_phoneCount: "",
    notesCount: "",
    address_1Count: "",
    zip_codeCount: "",
    imagePresent: "",
  };

  //fetching vender data from id
  const {
    data: venderData,
    isLoading: venderLoading,
    isError: venderIsError,
  } = useQuery({
    queryKey: ["get-vender-by-id", id],
    queryFn: () => getVenderList({ id: id }),
    staleTime: Infinity,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
    onSuccess: (data) => {
      if (data.status && !hasInvalidated) {
        // queryClient.invalidateQueries(["get-vender-by-id", id]);
        setHasInvalidated(true);
      }
    },
  });

  //Mutation hook for adding vender
  const {
    mutate: addNewVender,
    isLoading: isVenderAdding,
    error: venderAddError,
  } = useMutation({
    mutationKey: ["Add-vender"],
    mutationFn: (values) => addVender(values),
    onSuccess: (data) => {
      if (data.status) {
        navigate(`/vendor-list/create-vender?type=edit&id=${data.data[0].id}&from=fromEdit`);
      } else if (data.errors) {
        Swal.fire({
          icon: "info",
          // title: "Error",
          text: data.errors[0],
          focusConfirm: false,
        });
      } else {
        Swal.fire({
          icon: "info",
          // title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
      // queryClient.invalidateQueries(["get-vendor-list"]);
    },
  });
  const addnewVender = (values) => {
    try {
      if (planState === "true") {
        addNewVender(values);
      } else {
        navigate("/plan-selection");
      }
    } catch (error) {
      console.error("Error adding vender:", error);
    }
  };

  //Mutation hook for editing vender
  const {
    mutate: editVenderMutation,
    isLoading: isVenderEditing,
    error: venderEditError,
  } = useMutation({
    mutationKey: ["Edit-vender-by-ID"],
    mutationFn: (value) => editVenderDetails(value, id),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        if (activeTab === "2") {
          // if (window.history.length > 1) {
          if (from === "fromEdit" || page === "vendor") {
            if (from === "fromEdit") {
              toast.success("Vendor Updated Succesfully");
            } else {
              toast.success("Vendor Added Succesfully");
            }
            // navigate("/vendor-list");
          } else {
            // navigate("/vendor-list");
            toast.success("Vendor Added Succesfully");
          }
          // }
        }
        queryClient.invalidateQueries(["get-vender-by-id"]);
      }
      queryClient.invalidateQueries(["get-vendor-list"]);
    },
  });
  const editVenderFromList = (value) => {
    try {
      editVenderMutation(value);
    } catch (error) {
      console.error("Error editing vender:", error);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      if (!isEditOnly) {
        addnewVender(value);
      } else if (isEditOnly) {
        // editVenderFromList(value);
        editVenderFromList(getChangedValues(venderData?.data?.venders[0], formik.values));
      }
    },
  });

  //to set the values from the api when form is opened  edit type
  useEffect(() => {
    if (isEditOnly || isViewOnly) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...venderData?.data.venders[0],
          country: parseInt(venderData?.data?.venders[0]?.country),
          state: parseInt(venderData?.data?.venders[0]?.state),
          city: parseInt(venderData?.data?.venders[0]?.city),
          business_country: parseInt(venderData?.data?.venders[0]?.business_country),
          business_state: parseInt(venderData?.data?.venders[0]?.business_state),
          business_city: parseInt(venderData?.data?.venders[0]?.business_city),
          is_owner: parseInt(venderData?.data?.venders[0]?.is_owner),
          files: venderData?.data?.venders[0]?.profile_image_path,
          birthday: venderData?.data?.venders[0]?.dob,
          anniversary: venderData?.data?.venders[0]?.anniversary,
        };
      });
    }
  }, [isEditOnly, venderLoading, venderData]);

  // console.log("formik", formik.values)
  return (
    <>
      <NavigationHeader
        heading={from === "fromEdit" ? "Edit  Vendor" : from === "fromView" ? "Vendor Details" : " Add Vendor"}
        buttonInnerText={"Back"}
        route={"/vendor-list"}
        back
      />

      {venderLoading && isEditOnly ? (
        <div className="p-4">
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={400} rounded />
                  </Col>
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={400} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classNames({ active: activeTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                Business
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                // disabled
                disabled={!isViewOnly}
                tag="a"
                href="#tab"
                className={classNames({ active: activeTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                Home
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              Personal
            </NavLink>
          </NavItem> */}
            {/* <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("4");
              }}
            >
              Other
            </NavLink>
          </NavItem> */}
          </Nav>
          <form onSubmit={formik.handleSubmit} className="mt-3">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Business
                  toggle={toggle}
                  currentState={activeTab}
                  formik={formik}
                  isEditOnly={isEditOnly}
                  venderLoading={venderLoading}
                  isVenderAdding={isVenderAdding}
                  isVenderEditing={isVenderEditing}
                  isViewOnly={isViewOnly}
                />
              </TabPane>
              <TabPane tabId="2">
                <Home
                  toggle={toggle}
                  currentState={activeTab}
                  formik={formik}
                  isVenderEditing={isVenderEditing}
                  isViewOnly={isViewOnly}
                />
              </TabPane>
              {/* <TabPane tabId="3">
              <Personal toggle={toggle} currentState={activeTab} formik={formik} isEditOnly={isEditOnly} isVenderEditing={isVenderEditing} isViewOnly={isViewOnly}/>
            </TabPane> */}
              {/* <TabPane tabId="4">
              <Other
                toggle={toggle}
                currentState={activeTab}
                formik={formik}
                isEditOnly={isEditOnly}
                isVenderAdding={isVenderAdding}
                isVenderEditing={isVenderEditing}
                isViewOnly={isViewOnly}
              />
            </TabPane> */}
            </TabContent>
          </form>
        </section>
      )}
    </>
  );
};
export default CreateVenderPage;
