import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PlanRegistration from "./PlanRegistration";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { fetchPlanData } from "../../../http/get/getApi";
const Paypal = ({
  price,
  data,
  setModalData,
  modalTab,
  setModalTab,
  modal,
  setModal,
  invalidPlanData,
  refetchPlandata,
  publickey
}) => {
  const initialOptions = {
    "Client-id": publickey,
    currency: "USD",
    intent: "capture",
    "disable-funding": "card,venmo,sofort,ideal",
  };
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{ amount: { currency_code: "USD", value: price } }],
    });
  };
  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(function (details) {
        const payment_txn_id = details?.purchase_units?.[0]?.payments.captures?.[0]?.id;
        const payment_response = details;
        const amount = details?.purchase_units?.[0]?.amount.value;
        const status = details?.status;
        console.log("status :", status);
        console.log("amount :", amount);
        console.log("payment_txn_id :", payment_txn_id);
        console.log("payment_response :", JSON.stringify(payment_response));
        handleSubmitSubscribePayment(status, payment_txn_id, payment_response, amount);
      })
      .catch((error) => {
        console.error("Payment Capture Error:", error);
      });
  };

  const handleSubmitSubscribePayment = async (status, payment_txn_id, payment_response, amount) => {
    const planId = data?.id;
    const note = data?.name + " plan purchased";
    let paymentStatus = 0;
    if (status === "COMPLETED") {
      paymentStatus = 3;
    } else {
      toast.warning("Check payment status");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("status", paymentStatus);
      formData.append("payment_txn_id", payment_txn_id);
      formData.append("payment_response", payment_response);
      formData.append("amount", amount);
      formData.append("payer_id", "1");
      formData.append("payment_mode", "3");
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("mode", "3");
      formData.append("plan_id", planId);
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/subscribe-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      const planDataInfo = await planData.json();
      if (planDataInfo && planDataInfo.status === true) {
        toast.success("Plan Purchased Successfully");
        setModalData(planDataInfo);
        invalidPlanData();
        refetchPlandata();
        setTimeout(() => {
          setModal(!modal);
          setModalTab(!modalTab);
        }, 1000);
      }
    } catch (error) {
      console.log("Error :" + error);
    }
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      {" "}
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={(err) => console.log(err)}
        style={{
          layout: "vertical",
          // color: "blue",
          height: 30,
        }}
      />{" "}
    </PayPalScriptProvider>
  );
};
export default Paypal;
